import React from 'react';
import Footer from '../../reusable/Footer';
import Navbar from '../../reusable/Navbar';

function SyaratKetentuan() {
	return (
		<div>
			<Navbar />
			<div className="w-10/12 mx-auto text-justify my-6">
				<div className="mb-6">
					<h1 className="text-4xl text-gray-custom font-bold mb-3">Syarat & Ketentuan</h1>
					<p className="text-gray-custom text-lg mb-3">
						Pada dokumen Syarat dan Ketentuan Penggunaan (“S&K”) ini menyatakan syarat dan ketentuan yang perlu Anda
						penuhi dan setujui untuk dapat menggunakan aplikasi dan/atau situs yang kami sediakan (“Layanan”) dengan
						segala fitur dan kegunaannya, dan hubungan Anda dengan PT Maisyah Talenta Indonesia (“Maisyah” atau “kami”)
						sebagai penyedia Layanan. Harap baca S&K dengan seksama karena isinya akan mempengaruhi hak dan kewajiban
						Anda berdasarkan hukum. Jika Anda tidak menyetujui S&K ini, mohon untuk tidak mendaftar atau menggunakan
						Layanan.
					</p>
					<p className="text-gray-custom text-lg mb-3">
						Penggunaan Layanan melibatkan beberapa pihak yang masing-masing memiliki hak dan kewajiban yang perlu
						dipenuhi. Pihak-pihak tersebut adalah:
					</p>
					<ol className="list-decimal ml-12 text-gray-custom text-lg">
						<li>Maisyah, sebagai penyedia Layanan.</li>
						<li>
							Pengguna Layanan (“Pengguna”), yaitu individu yang bertujuan untuk mencari informasi lowongan pekerjaan
							dan/atau melamar pekerjaan (“Pencari Kerja”) dan individu atau entitas bisnis yang sedang mencari pekerja
							atau karyawan pada Layanan (“Perusahaan”) serta individu yang menggunakan Layanan untuk mengakses
							soal-soal pada fitur Belajar yang terdapat pada Layanan.
						</li>
					</ol>
				</div>
				<div className="mb-6">
					<h2 className="text-3xl text-gray-custom font-bold mb-2">Ketentuan Umum Pengguna</h2>
					<p className="text-gray-custom text-lg mb-3">Dengan menggunakan Layanan, Anda menyatakan bahwa:</p>
					<ol className="list-decimal ml-12 text-gray-custom text-lg">
						<li>
							Anda setuju untuk terikat dengan S&K yang ditetapkan serta kebijakan mengenai pembaruan S&K di kemudian
							hari. Anda dipersilahkan untuk tidak menggunakan dan/atau mengakses Layanan jika tidak setuju untuk
							terikat dengan S&K ini atau pun pembaruannya.
						</li>
						<li>
							Apabila Anda belum dewasa menurut ketentuan perundang-undangan yang berlaku, wajib mendapatkan bimbingan
							dan pengawasan orang tua dalam menggunakan Layanan.
						</li>
						<li>
							Jika ada pertentangan antara S&K dengan kontrak apa pun yang Anda miliki dengan Maisyah, Ketentuan ini
							yang akan diutamakan (kecuali dinyatakan lain).
						</li>
					</ol>
				</div>
				<div className="mb-6">
					<h2 className="text-2xl text-gray-custom font-bold mb-3">A. Registrasi</h2>
					<ol className="list-decimal ml-12 text-gray-custom text-lg">
						<li>Untuk mendaftarkan akun pada Layanan, anda harus berusia 18 tahun ke atas.</li>
						<li>Data-data yang anda masukkan untuk proses registrasi harus merupakan data tepat dan lengkap.</li>
						<li>
							Anda diperbolehkan mendaftar atas nama pribadi atau perusahaan tempat anda bekerja (apabila Anda memiliki
							hak), dan tidak diperbolehkan mendaftar atas nama orang atau perusahaan lain.
						</li>
						<li>Anda bertanggung jawab untuk menjaga kerahasiaan data yang Anda gunakan untuk masuk ke akun Anda.</li>
						<li>
							Dalam hal Anda lupa kata sandi akun, Anda dapat mengikuti langkah- langkah yang terdapat pada Layanan
							untuk membuat kata sandi baru. Apabila terdapat langkah tertentu yang tidak dapat Anda penuhi, maka anda
							tidak akan mendapatkan akses untuk membuat kata sandi baru.
						</li>
						<li>
							Jika kami memiliki alasan untuk meyakini bahwa akan terjadi pelanggaran keamanan atau penyalahgunaan akun
							Anda oleh pihak yang tidak berhak, kami akan meminta Anda untuk mengubah kata sandi Anda atau kami dapat
							menangguhkan akun Anda demi keamanan.
						</li>
					</ol>
				</div>
				<div className="mb-6">
					<h2 className="text-2xl text-gray-custom font-bold mb-3">B. Kekayaan Intelektual</h2>
					<p className="text-gray-custom text-lg mb-3">
						Aplikasi, situs, akun media sosial, dan semua layanan yang dimiliki Maisyah adalah milik eksklusif Maisyah
						atau pemberi lisensinya.
					</p>
					<ol className="list-decimal ml-12 text-gray-custom text-lg">
						<li>Maisyah dan Layanan dilindungi oleh hak cipta, merek dagang, dan hak kekayaan intelektual lainnya.</li>
						<li>
							Anda dapat menggunakan dan menikmati layanan dan tampilan Layanan. Anda tidak boleh mereproduksi,
							memodifikasi, menyalin atau mendistribusikan atau menggunakan untuk tujuan komersial hal apa pun di
							Layanan, tanpa izin tertulis dari Maisyah.
						</li>
					</ol>
					<p className="text-gray-custom text-lg mb-3">
						Setiap pengguna Layanan setuju untuk tidak menggunakan Layanan dengan cara apa pun yang melanggar hak
						kekayaan intelektual atau hak milik orang lain seperti:
					</p>
					<ol className="list-decimal ml-12 text-gray-custom text-lg ">
						<li>
							Mencetak, mengunduh, menggandakan, mengirimkan atau menyalin, mereproduksi, mendistribusikan ulang,
							menerbitkan ulang, atau menggunakan informasi pribadi apapun tentang pengguna lain, kecuali telah
							mendapatkan izin tertulis atau dinyatakan lain pada Syarat & Ketentuan;
						</li>
						<li>Merekayasa balik (reverse engineer) atau mendekompilasi bagian mana pun dari Layanan;</li>
						<li>
							Membuat akun dengan identitas orang lain, menggunakan nama perusahaan secara tanpa hak, atau mengunggah
							lowongan pekerjaan secara tanpa hak;
						</li>
						<li>Menghapus atau mengubah materi apa pun yang diunggah oleh orang atau entitas lain mana pun;</li>
						<li>
							Mengakses data yang tidak ditujukan untuk Anda atau masuk ke server atau akun yang tidak diizinkan untuk
							Anda akses; dan
						</li>
						<li>
							Hal-hal lain yang dilakukan secara tanpa hak dan dapat menimbulkan kerugian secara materiil atau pun
							immateriil bagi pihak lain.
						</li>
					</ol>
				</div>
				<div className="mb-6">
					<h2 className="text-2xl text-gray-custom font-bold mb-3">C. Penggunaan Layanan</h2>
					<p className="text-gray-custom text-lg mb-3">Layanan dapat diakses bagi:</p>
					<ol className="list-decimal ml-12 text-gray-custom text-lg mb-3">
						<li>Individu yang ingin mencari informasi mengenai lowongan pekerjaan dan/atau melamar pekerjaan;</li>
						<li>Perusahaan atau pencari kandidat pekerja untuk lowongan kerja yang valid;</li>
						<li>
							Individu yang tergabung atau pun memiliki minat untuk bergabung di komunitas yang tersedia pada Layanan;
						</li>
						<li>
							Setiap orang yang ingin mengetahui fitur yang terdapat pada Layanan tanpa adanya iktikad buruk untuk
							melanggar Hak Kekayaan Intelektual yang dimiliki Maisyah.
						</li>
					</ol>
					<p className="text-gray-custom text-lg mb-3">
						Penggunaan Anda atas Layanan juga tunduk pada kontrak lain yang mungkin Anda miliki dengan Maisyah. Jika ada
						pertentangan antara S&K dan kontrak apa pun yang Anda miliki dengan Maisyah, Ketentuan ini yang akan berlaku
						(kecuali dinyatakan lain).
					</p>
					<p className="text-gray-custom text-lg mb-3">
						Dengan menggunakan Layanan yang terdapat pada Layanan, anda menyatakan bahwa:
					</p>
					<ol className="list-decimal ml-12 text-gray-custom text-lg mb-3">
						<li>Tidak akan melanggar hak kekayaan intelektual milik orang lain yang berhubungan dengan Layanan;</li>
						<li>
							Tidak akan membagikan informasi kredensial untuk masuk ke akun Layanan Anda pada pihak ketiga mana pun;
						</li>
						<li>Melanggar atau berusaha melanggar sistem keamanan Layanan;</li>
						<li>Tidak menggunakan akun secara tanpa hak;</li>
						<li>
							Tidak akan melakukan penipuan, pengecohan, diskriminasi, atau pun penggunaan Layanan dengan cara-cara lain
							yang tidak diperbolehkan pada S&K dan/atau hukum yang berlaku;
						</li>
						<li>Bertanggung jawab atas setiap hal yang Anda unggah; dan</li>
						<li>Setuju dengan segala isi Syarat & Ketentuan dan Kebijakan Privasi Maisyah.</li>
					</ol>
				</div>
				<div className="mb-6">
					<h2 className="text-2xl text-gray-custom font-bold mb-3">D. Ketersediaan Layanan Maisyah</h2>
					<ol className="list-decimal ml-12 text-gray-custom text-lg mb-3">
						<li>
							Kami memiliki tujuan untuk menawarkan layanan terbaik kepada Anda, namun kami tidak berjanji bahwa Layanan
							kami akan memenuhi kebutuhan semua Anda.
						</li>
						<li>
							Kami tidak dapat menjamin bahwa Layanan akan bebas dari kesalahan, bebas dari error, atau bahwa Layanan
							bebas dari virus atau mekanisme berbahaya lainnya. Jika terjadi kesalahan pada Layanan, Anda harus
							melaporkannya melalui Customer Service Maisyah melalui WhatsApp dengan nomor 085211110097 dan kami akan
							berusaha memperbaiki kesalahan tersebut sesegera mungkin.
						</li>
						<li>
							Akses Anda ke Layanan mungkin akan dibatasi pada waktu tertentu saat adanya perbaikan, pemeliharaan, atau
							pengenalan konten, fasilitas, atau layanan baru. Kami akan berusaha memulihkan akses pada Layanan sesegera
							mungkin.
						</li>
					</ol>
				</div>
				<div className="mb-6">
					<h2 className="text-2xl text-gray-custom font-bold mb-3">E. Perusahaan</h2>
					<ol className="list-decimal ml-12 text-gray-custom text-lg mb-3">
						<li>
							Perusahaan harus mengisi data-data profil Perusahaan yang diminta pada Layanan secara lengkap dan tepat.
						</li>
						<li>Perusahaan bertanggung jawab atas setiap lowongan kerja yang mereka unggah.</li>
						<li>
							Maisyah tidak akan mengubah unggahan lowongan kerja Perusahaan secara sepihak, kecuali telah terdapat
							kesepakatan lain sebelumnya.
						</li>
						<li>Perusahaan dilarang untuk memungut biaya selama proses rekrutmen melalui Layanan.</li>
						<li>Perusahaan wajib menjunjung tinggi kesopanan dalam berkomunikasi dengan setiap pihak pada Layanan.</li>
						<li>
							Lowongan kerja yang Perusahaan unggah tidak boleh mengandung unsur diskriminatif, atau penipuan, atau
							pengecohan yang merugikan Pencari Kerja dan/atau Maisyah.
						</li>
						<li>
							Apabila terdapat suatu lowongan kerja yang terindikasi penipuan, kami akan menghubungi Perusahaan terkait.
							Apabila kami tidak mendapatkan jawaban yang meyakinkan pada batas waktu tertentu, Maisyah berhak untuk
							menghapus lowongan kerja tersebut.
						</li>
						<li>
							Maisyah dapat menghapus atau menonaktifkan akun Perusahaan jika dianggap telah melanggar S&K yang berlaku.
						</li>
						<li>
							Maisyah tidak memiliki tanggung jawab hukum atas ketidaktepatan informasi lowongan kerja pada Layanan.
						</li>
					</ol>
				</div>
				<div className="mb-6">
					<h2 className="text-2xl text-gray-custom font-bold mb-3">F. Pencari Kerja</h2>
					<ol className="list-decimal ml-12 text-gray-custom text-lg mb-3">
						<li>
							Maisyah dengan ini memberi Anda hak non-eksklusif yang terbatas, dapat dihentikan, untuk mengakses dan
							menggunakan Layanan hanya untuk penggunaan pribadi Anda mencari peluang kerja untuk diri Anda sendiri dan
							bukan untuk orang lain. Saat Anda mendaftar di Layanan, Anda akan diminta untuk membuat akun dan
							memberikan informasi tertentu kepada Maisyah.
						</li>
						<li>
							Setiap data pada profil yang Anda isi harus akurat, lengkap, terkini dan tidak menyesatkan. Anda tidak
							boleh mengisi data pada profil dengan data orang lain.
						</li>
						<li>
							Anda setuju bahwa Anda bertanggung jawab penuh atas bentuk, isi, dan keakuratan setiap data yang anda
							berikan pada profil akun Anda.
						</li>
						<li>
							Maisyah berhak untuk menawarkan layanan dan produk pihak ketiga kepada Anda dan setelah penawaran tersebut
							dapat dibuat oleh Layanan atau oleh pihak ketiga.
						</li>
						<li>
							Anda memahami bahwa semua informasi yang Anda berikan, data profil Anda, resume, dan/atau informasi profil
							harus dan akan diungkapkan kepada Perusahaan.
						</li>
						<li>Anda diwajibkan menjunjung tinggi kesopanan dalam berkomunikasi dengan setiap pihak pada Layanan.</li>
						<li>
							Maisyah berhak untuk menghapus atau menonaktifkan akun Anda dan semua data Anda setelah lama tidak aktif
							atau dianggap melanggar S&K yang berlaku.
						</li>
						<li>
							Anda dapat melaporkan lowongan kerja yang terindikasi penipuan dengan menghubungi Customer Service Maisyah
							melalui WhatsApp dengan nomor 085211110097
						</li>
					</ol>
				</div>
				<div className="mb-6">
					<h2 className="text-2xl text-gray-custom font-bold mb-3">G. Tautan Pihak Ketiga</h2>
					<p className="text-gray-custom text-lg mb-3">
						Layanan mungkin berisi tautan ke situs lain yang dimiliki oleh selain Maisyah. Kami tidak bertanggung jawab
						atas isi situs-situs tersebut. Kami memiliki hak, tetapi bukanlah merupakan suatu keharusan untuk melarang
						penggunaan tautan situs lain di Layanan. Kami akan menyajikan beberapa tautan yang dapat berfungsi sebagai
						informasi mengenai situs dari suatu Perusahaan atau tautan yang digunakan untuk melaksanakan proses
						rekrutmen (contoh: wawancara) namun bukan merupakan bentuk dukungan dari Maisyah kepada konten di situs web
						pihak ketiga tersebut.
					</p>
				</div>
				<div className="mb-6">
					<h2 className="text-2xl text-gray-custom font-bold mb-3">H. Yurisdiksi dan Hukum yang Berlaku</h2>
					<p className="text-gray-custom text-lg mb-3">
						S&K ini diatur berdasarkan hukum yang pada saat ini berlaku di Indonesia. Dengan menggunakan Layanan, Anda
						sepakat untuk terikat pada yurisdiksi pengadilan Republik Indonesia.
					</p>
				</div>
				<div className="mb-6">
					<h2 className="text-2xl text-gray-custom font-bold mb-3">I. Ganti Rugi</h2>
					<p className="text-gray-custom text-lg mb-3">
						Pengguna setuju untuk tidak membebankan ganti rugi yang timbul dari atau terkait dengan penggunaan Layanan
						oleh pengguna atau kontennya atau pelanggaran pengguna terhadap S&K, untuk sejauh tidak dilarang oleh hukum
						yang berlaku. Ganti rugi ini akan menjadi tambahan untuk semua kewajiban pengguna lainnya berdasarkan S&K,
						dan tidak akan mengurangi hak atau upaya hukum lain yang tersedia menurut hukum untuk Maisyah.
					</p>
				</div>
				<div className="mb-6">
					<h2 className="text-2xl text-gray-custom font-bold mb-3">J. Keterpisahan</h2>
					<p className="text-gray-custom text-lg mb-3">
						Syarat dan ketentuan di dalam S&K ini akan dapat diberlakukan secara terpisah dengan satu sama lain dan
						keabsahan satu syarat dan/atau ketentuan tidak akan berpengaruh dengan ketentuan lainnya yang tidak sah.
						Dalam hal terdapat ketentuan atau bagian dari S&K yang tidak sesuai dengan ketentuan hukum yang berlaku,
						tidak sah atau tidak dapat dilaksanakan, keabsahan dan keberlakuan syarat dan ketentuan yang lainnya tidak
						akan terpengaruh, dan sebagai pengganti dari syarat atau ketentuan yang tidak sesuai dengan ketentuan hukum
						yang berlaku, tidak sah, atau tidak dapat dilaksanakan, akan ditambahkan sebagai bagian dari Syarat dan
						Ketentuan satu atau lebih ketentuan yang serupa dalam hal yang mungkin benar, sah, dan dapat dilaksanakan
						berdasarkan hukum yang berlaku.
					</p>
				</div>
				<div className="mb-6">
					<h2 className="text-2xl text-gray-custom font-bold mb-3">K. Pembaruan Syarat & Ketentuan</h2>
					<p className="text-gray-custom text-lg mb-3">
						Maisyah berhak untuk mengubah, menambah, menghapus atau mengubah S&K sewaktu-waktu tanpa pemberitahuan
						sebelumnya. Anda disarankan untuk meninjau S&K secara berkala. Apabila di kemudian hari anda merasa
						keberatan dengan perubahan S&K pada Layanan, sebaiknya Anda menghentikan penggunaan. Penggunaan yang
						berlanjut merupakan bentuk penerimaan Anda atas semua perubahan yang diterapkan pada S&K ini.
					</p>
				</div>
			</div>
			<Footer />
		</div>
	);
}

export default SyaratKetentuan;
