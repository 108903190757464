import { isEmpty } from "lodash";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axiosConfig from "../../../../utils/axiosConfig";
import { API_ENDPOINT } from "../../../../utils/config";
import { getTimeJob } from "../../../../utils/getTimeJob";
import isAuthenticated from "../../../../utils/isAuthenticated";
import numeral from "../../../../utils/numeralLocale";
import { Toast } from "../../../reusable/Toast";
import ModalLamar from "./ModalLamar";

const limitWord = (text) => {
  if (text.length > 220) {
    return `${text.slice(0, 220)} .....`;
  }
  return text;
};

function JobCard({ job }) {
  const [modalLamar, setModalLamar] = useState(false);

  const [bookmark, setBookmark] = useState(false);

  const navigate = useNavigate();

  const onBookmark = async (e) => {
    try {
      e.preventDefault();
      if (!isAuthenticated()) {
        navigate("/login");
        return;
      }
      if (bookmark) {
        const response = await axiosConfig.delete(
          `${API_ENDPOINT}/pencaker/bookmark`,
          { data: { job_id: job.id } }
        );
      } else {
        const response = await axiosConfig.post(
          `${API_ENDPOINT}/pencaker/bookmark`,
          { job_id: job.id }
        );
      }
      setBookmark(!bookmark);
    } catch (error) {
      Toast.fire({
        icon: "error",
        iconColor: "#EA4735",
        title: error.response?.data.info ?? "Terjadi Suatu Error",
      });
    }
  };

  const onLamar = (e) => {
    e.preventDefault();

    if (!isAuthenticated()) {
      navigate("/login");
      return;
    }
    setModalLamar(true);
  };

  useEffect(() => {
    setBookmark(job.is_bookmark);
  }, []);

  return (
    <>
      {modalLamar && (
        <ModalLamar
          modalLamar={modalLamar}
          setModalLamar={setModalLamar}
          bookmark={bookmark}
          setBookmark={setBookmark}
          job={job}
        />
      )}

      <Link to={`/lowongan/${job.id}`}>
        <div
          className={`cursor-pointer border-2 rounded-xl border-gray-300 p-4 h-full lg:p-6 flex flex-col gap-2 lg:gap-6 hover:shadow-lg transition ease-in-out duration-300 ${
            job.is_show === false && "bg-gray-200"
          }`}
        >
          <div className="grid grid-cols-9 gap-2 items-center cursor-pointer">
            <div className="col-span-8 grid grid-cols-7 gap-6">
              <div className="col-span-2">
                <img
                  src={
                    job.company.profile_picture ??
                    "/images/profile-default-company.png"
                  }
                  className="w-full"
                  alt=""
                />
              </div>
              <div className="flex flex-col gap-2 col-span-5">
                <h1 className="font-bold text-clg">{job.posisi_pekerjaan}</h1>
                <Link to={`/company/${job.company.id}`}>
                  <h2 className="text-primary text-csm">
                    {job.company.nama_perusahaan}
                  </h2>
                </Link>
                <h1 className="text-gray-400">{getTimeJob(job.created_at)}</h1>
              </div>
            </div>
            <div onClick={onBookmark}>
              <img
                src={
                  bookmark
                    ? "/images/lowongan/bookmark-filled-orange.svg"
                    : "/images/lowongan/bookmark-outlined.svg"
                }
                className="w-10 transition delay-100 hover:-translate-y-2"
                alt=""
              />
            </div>
          </div>
          <div className="flex flex-col gap-4">
            <div className="flex gap-4 items-center text-csm">
              <div>
                <img
                  src="/images/lowongan/building.png"
                  className="w-4 lg:w-5"
                  alt=""
                />
              </div>
              <p className="capitalize">
                {isEmpty(job.district) ? (
                  <>{job.lokasi_display}</>
                ) : (
                  <>
                    {job.district.name.toLowerCase()} -{" "}
                    {job.district.city.name.toLowerCase()} ({job.lokasi_display}
                    )
                  </>
                )}
              </p>
            </div>
            <div className="flex gap-4 items-center text-csm">
              <div>
                <img
                  src="/images/lowongan/uang.png"
                  className="w-4 lg:w-5"
                  alt=""
                />
              </div>
              {job.is_display_salary ? (
                <p>
                  Rp {numeral(job.batas_bawah_gaji).format("0,0")} - Rp{" "}
                  {numeral(job.batas_atas_gaji).format("0,0")} / bulan
                </p>
              ) : (
                <p>Perusahaan tidak menampilkan gaji</p>
              )}
            </div>
            <div className="flex gap-4 items-center text-csm">
              <div>
                <img
                  src="/images/lowongan/job.png"
                  className="w-4 lg:w-5"
                  alt=""
                />
              </div>
              <p>Pengalaman {job.pengalaman}</p>
            </div>
          </div>
          <p className="tracking-wide leading-7 text-justify text-csm">
            {limitWord(job.job_desc)}
          </p>
          {job.is_show && (
            <div className="flex justify-end items-center gap-2">
              <div
                className="underline text-secondary cursor-pointer"
                onClick={onLamar}
              >
                Lamar Sekarang
              </div>
              <div>
                <img src="/images/arrow-orange.png" className="w-6" alt="" />
              </div>
            </div>
          )}
        </div>
      </Link>
    </>
  );
}

export default JobCard;
