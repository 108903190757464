import { isEmpty } from "lodash";
import { Link } from "react-router-dom";
import { getTimeJob } from "../../../../../utils/getTimeJob";
import numeral from "../../../../../utils/numeralLocale";

const StatusComponent = ({ status }) => {
  if (status === "failed") {
    return (
      <div className="rounded-full bg-red-custom bg-opacity-10 text-red-custom font-bold text-center text-sm px-5 py-3 lg:text-xl">
        Tidak Lolos
      </div>
    );
  } else if (status === "sent") {
    return (
      <div className="rounded-full bg-primary bg-opacity-10 text-primary font-bold text-center text-sm px-5 py-3 lg:text-xl">
        Lamaran Terkirim
      </div>
    );
  } else if (status === "review") {
    return (
      <div className="rounded-full bg-yellow-400 bg-opacity-10 text-yellow-400 font-bold text-center text-sm px-5 py-3 lg:text-xl">
        Sedang direview
      </div>
    );
  } else if (status === "qualify") {
    return (
      <div className="rounded-full bg-green-custom bg-opacity-10 text-green-custom font-bold text-center text-sm px-5 py-3 lg:text-xl">
        Lolos Kualifikasi
      </div>
    );
  } else if (status === "expired") {
    return (
      <div className="rounded-full bg-gray-custom bg-opacity-10 text-gray-custom font-bold text-center text-sm px-5 py-3 lg:text-xl">
        Expired
      </div>
    );
  } else {
    return <></>;
  }
};

function JobCard({ data }) {
  return (
    <div className="p-4 lg:p-8 shadow-lg border-2 rounded-xl">
      <div className="flex justify-between items-center">
        <div className="flex gap-6">
          <div>
            <img
              src={data.job.company.profile_picture}
              className="w-16 lg:w-24 rounded-full"
              alt=""
            />
          </div>
          <div>
            <div className="text-lg lg:text-2xl font-bold">
              {data.job.posisi_pekerjaan}
            </div>
            <div className="text-sm lg:text-base text-primary">
              {data.job.company.nama_perusahaan}
            </div>
            <div className="hidden lg:flex text-gray text-opacity-60 mt-2 text-md">
              Melamar {getTimeJob(data.created_at)}
            </div>
            <div className="flex lg:hidden text-gray text-opacity-60 mt-2 text-md">
              {getTimeJob(data.created_at)}
            </div>
          </div>
        </div>
        <StatusComponent status={data.status} />
      </div>
      <div className="my-6 flex flex-col gap-4 text-lg">
        <div className="flex gap-4 items-center">
          <div>
            <img src="/images/lowongan/building.png" className="w-5" alt="" />
          </div>
          <div className="capitalize">
            {isEmpty(data.job.district) ? (
              <>{data.job.lokasi_display}</>
            ) : (
              <>
                {data.job.district.name.toLowerCase()} -{" "}
                {data.job.district.city.name.toLowerCase()} (
                {data.job.lokasi_display})
              </>
            )}
          </div>
        </div>
        <div className="flex gap-4 items-center">
          <div>
            <img src="/images/lowongan/uang.png" className="w-5" alt="" />
          </div>
          {data.job.is_display_salary ? (
            <div>
              Rp {numeral(data.job.batas_bawah_gaji).format("0,0")} - Rp{" "}
              {numeral(data.job.batas_atas_gaji).format("0,0")} / bulan
            </div>
          ) : (
            <div>Perusahaan tidak menampilkan gaji</div>
          )}
        </div>
        <div className="flex gap-4 items-center">
          <div>
            <img src="/images/lowongan/job.png" className="w-5" alt="" />
          </div>
          <div>Pengalaman {data.job.pengalaman}</div>
        </div>
      </div>
      <div className="border-b lg:border-none border-gray-custom border-opacity-30 w-full my-9 mx-auto"></div>
      <div className="flex lg:hidden justify-center lg:justify-end items-center gap-2  lg:border-none">
        <Link to={`/lowongan/${data.job.id}`}>
          <p className="underline text-secondary cursor-pointer lg:text-2xl font-bold lg:font-normal text-center">
            Lihat detail loker
          </p>
        </Link>
        <div>
          <img src="/images/arrow-orange.png" className="w-6" alt="" />
        </div>
      </div>
    </div>
  );
}

export default JobCard;
