import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useSearchParams } from 'react-router-dom';
import { setDone, setLoading } from '../../../store/loading';
import { setUser } from '../../../store/user';
import axiosConfig from '../../../utils/axiosConfig';
import { API_ENDPOINT } from '../../../utils/config';
import { Toast } from '../../reusable/Toast';

function Verification({ setView, setInfo }) {
	const [searchParams, setSearchParams] = useSearchParams();

	const id = searchParams.get('id');

	const dispatch = useDispatch();

	const [code, setCode] = useState('');
	const [isCountDownComplete, setIsCountDownComplete] = useState(false);
	const [countDownTime, setCountDownTime] = useState(30);

	const onSubmit = async () => {
		try {
			dispatch(setLoading());
			const response = await axiosConfig.post(`${API_ENDPOINT}/pencaker/email/verification`, {
				id,
				verification_code: code,
			});
			dispatch(setUser(response.data.data));
			dispatch(setDone());

			setView('success');
		} catch (error) {
			console.log(error);
			setView('failed');
			setInfo(error.response.data.info);
			dispatch(setDone());
		}
	};

	const resendEmail = async () => {
		try {
			dispatch(setLoading());
			const response = await axiosConfig.post(`${API_ENDPOINT}/pencaker/email/resend`, {
				user_id: id,
			});
			setCountDownTime(30);
			Toast.fire({
				icon: 'success',
				title: 'Berhasil Mengirim Email',
				iconColor: '#17afe3',
			});
		} catch (error) {
			console.log(error);
			if (error.response.status === 429) {
				Toast.fire({
					icon: 'error',
					title: 'Silahkan tunggu 60 detik',
				});
			} else {
				Toast.fire({
					icon: 'error',
					title: error.response.message,
				});
			}
		} finally {
			dispatch(setDone());
		}
	};

	useEffect(() => {
		if (countDownTime === 0) {
			setCountDownTime(null);
			setIsCountDownComplete(true);
		}
		if (!countDownTime) {
			return;
		}
		const intervalCountDown = setInterval(() => setCountDownTime(countDownTime - 1), 1000);
		return () => clearInterval(intervalCountDown);
	}, [countDownTime]);

	return (
		<div className="antialiased h-screen flex flex-col text-gray-custom">
			{/* HEADER  */}
			<nav className="hidden lg:flex items-center justify-between flex-wrap bg-teal w-11/12 mx-auto py-6">
				<Link to="/">
					<div className="w-40 cursor-pointer">
						<img layout="responsive" width={180} height={55} src="/images/maisyah.svg" alt="" />
					</div>
				</Link>
			</nav>
			<nav className="flex flex-col gap-2 w-10/12 mx-auto mt-24 mb-5 lg:hidden">
				<div className="text-3xl font-bold">Verifikasi</div>
				<div className="">Cek e-mail kamu dan masukkan Kode Verifikasi </div>
			</nav>
			<div
				className="flex-grow flex flex-col items-center justify-center w-full bg-contain bg-no-repeat bg-bottom "
				style={{ backgroundImage: "url('/images/bg-small.png')" }}
			>
				<div className="md:w-136 2xl:w-1/3 flex flex-col gap-4 lg:rounded-lg lg:drop-shadow-lg lg:bg-white lg:p-16 -mt-48 lg:-mt-16">
					<div className="hidden lg:block">
						<div className="text-center text-3xl font-bold">Verifikasi</div>
						<div className="text-center font-light mb-8">
							Cek e-mail kamu dan masukkan <span className="font-bold">Kode Verifikasi</span>
						</div>
					</div>
					<div id="otp" className="flex justify-center text-center mb-8">
						<input
							className="border-b border-sky-300 text-center rounded outline-none py-2 focus:border-primary focus:border-b-2 text-xl"
							type="text"
							onChange={e => setCode(e.target.value)}
						/>
					</div>
					<button
						onClick={onSubmit}
						className="w-full flex justify-center rounded-xl bg-secondary font-bold py-5 lg:py-4 hover:bg-opacity-90 text-white mb-4 text-lg cursor-pointer"
					>
						Submit
					</button>
					<div className="text-center">
						Tidak dapat kode?{' '}
						{isCountDownComplete ? (
							<span
								onClick={() => {
									resendEmail();
									setIsCountDownComplete(false);
								}}
								className="text-secondary font-bold cursor-pointer"
							>
								Kirim Lagi
							</span>
						) : (
							<span className="text-gray-custom font-bold">Kirim Lagi {countDownTime}</span>
						)}
					</div>
				</div>
			</div>
		</div>
	);
}

export default Verification;
