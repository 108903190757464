import { Checkbox } from 'antd';
import React from 'react';
import { CSSTransition } from 'react-transition-group';

function SideMenu({ menu, setDisplayMenu, onChangeCheckbox, sort, setSort }) {
	return (
		<div className="w-3/12 hidden lg:block">
			<div className="" style={{ transition: 'all 1s ease-in' }}>
				<div className="flex justify-between items-center gap-6 cursor-pointer" onClick={() => setDisplayMenu('sort')}>
					<div className="flex items-center gap-6">
						<div>
							<img src="/images/lowongan/sort.png" className="w-8" alt="" />
						</div>
						<div className="font-semibold text-xl">Urutkan</div>
					</div>
					<div>
						<CSSTransition in={menu.sort} timeout={200} classNames="arrow-" unmountOnExit={false}>
							<img src="/images/lowongan/arrow-down.png" className="w-5" alt="" />
						</CSSTransition>
					</div>
				</div>
				<CSSTransition in={menu.sort} timeout={200} classNames="menu-" unmountOnExit>
					<div className="w-10/12 my-6">
						<div
							onClick={() => setSort('new')}
							className={`px-4 py-4 text-lg w-full rounded-lg ${
								sort === 'new' && 'text-secondary font-bold bg-secondary bg-opacity-20'
							}`}
						>
							Paling Baru
						</div>
						<div
							onClick={() => setSort('expired')}
							className={`px-4 py-4 text-lg w-full rounded-lg ${
								sort === 'expired' && 'text-secondary font-bold bg-secondary bg-opacity-20'
							}`}
						>
							Segera Berakhir
						</div>
						<div
							onClick={() => setSort('gaji')}
							className={`px-4 py-4 text-lg w-full rounded-lg ${
								sort === 'gaji' && 'text-secondary font-bold bg-secondary bg-opacity-20'
							}`}
						>
							Gaji Tertinggi
						</div>
					</div>
				</CSSTransition>
			</div>
			<div className="my-12">
				<div className="flex items-center gap-6">
					<div>
						<img src="/images/lowongan/filter.png" className="w-8" alt="" />
					</div>
					<div className="font-semibold text-xl">Filter</div>
				</div>
				<div className="border-b border-gray-custom border-opacity-30 w-11/12 my-8 mx-auto"></div>
				<div className="w-11/12 mx-auto">
					<div
						className="my-4 w-full flex justify-between items-center cursor-pointer"
						onClick={() => setDisplayMenu('type')}
					>
						<div className="text-lg font-bold">Tipe Pekerjaan</div>
						<div>
							<CSSTransition in={menu.type} timeout={200} classNames="arrow-" unmountOnExit={false}>
								<img src="/images/lowongan/arrow-down.png" className="w-5" alt="" />
							</CSSTransition>
						</div>
					</div>
					<CSSTransition in={menu.type} timeout={200} classNames="menu-" unmountOnExit>
						<div className="text-lg flex flex-col gap-4">
							<div>
								<Checkbox onChange={event => onChangeCheckbox(event)} name="type" value="fulltime">
									Full-time
								</Checkbox>
							</div>
							<div>
								<Checkbox onChange={event => onChangeCheckbox(event)} name="type" value="parttime">
									Part-time
								</Checkbox>
							</div>
							<div>
								<Checkbox onChange={event => onChangeCheckbox(event)} name="type" value="internship">
									Internship / Magang
								</Checkbox>
							</div>
							<div>
								<Checkbox onChange={event => onChangeCheckbox(event)} name="type" value="freelance">
									Freelance
								</Checkbox>
							</div>
							<div>
								<Checkbox onChange={event => onChangeCheckbox(event)} name="type" value="contract">
									Kontrak
								</Checkbox>
							</div>
						</div>
					</CSSTransition>
				</div>
				<div className="border-b border-gray-custom border-opacity-30 w-11/12 my-8 mx-auto"></div>
				<div className="w-11/12 mx-auto">
					<div
						className="my-4 w-full flex justify-between items-center cursor-pointer"
						onClick={() => setDisplayMenu('experience')}
					>
						<div className="text-lg font-bold">Pengalaman</div>
						<div>
							<CSSTransition in={menu.experience} timeout={200} classNames="arrow-" unmountOnExit={false}>
								<img src="/images/lowongan/arrow-down.png" className="w-5" alt="" />
							</CSSTransition>
						</div>
					</div>
					<CSSTransition in={menu.experience} timeout={200} classNames="menu-" unmountOnExit>
						<div className="text-lg flex flex-col gap-4">
							<div>
								<Checkbox onChange={event => onChangeCheckbox(event)} name="pengalaman" value="3 bulan">
									3 Bulan
								</Checkbox>
							</div>
							<div>
								<Checkbox onChange={event => onChangeCheckbox(event)} name="pengalaman" value="6 bulan">
									6 Bulan
								</Checkbox>
							</div>
							<div>
								<Checkbox onChange={event => onChangeCheckbox(event)} name="pengalaman" value="1 tahun">
									1 Tahun
								</Checkbox>
							</div>
							<div>
								<Checkbox onChange={event => onChangeCheckbox(event)} name="pengalaman" value="2 tahun">
									2 Tahun
								</Checkbox>
							</div>
							<div>
								<Checkbox onChange={event => onChangeCheckbox(event)} name="pengalaman" value="3 tahun">
									3 Tahun
								</Checkbox>
							</div>
							<div>
								<Checkbox onChange={event => onChangeCheckbox(event)} name="pengalaman" value="5 tahun">
									5 Tahun
								</Checkbox>
							</div>
							<div>
								<Checkbox onChange={event => onChangeCheckbox(event)} name="pengalaman" value="10 tahun">
									10 Tahun
								</Checkbox>
							</div>
							<div>
								<Checkbox onChange={event => onChangeCheckbox(event)} name="pengalaman" value="Lebih dari 10 tahun">
									&gt; 10 tahun
								</Checkbox>
							</div>
						</div>
					</CSSTransition>
				</div>
				<div className="border-b border-gray-custom border-opacity-30 w-11/12 my-8 mx-auto"></div>
			</div>
		</div>
	);
}

export default SideMenu;
