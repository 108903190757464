import { isEmpty } from 'lodash';
import React from 'react';
import { Link } from 'react-router-dom';

function CompanyCard({ company }) {
	return (
		<Link to={`/company/${company.id}`}>
			<div
				className={`cursor-pointer border-2 rounded-xl border-gray-300 p-2 h-full lg:p-6 flex flex-col gap-2 lg:gap-4 transition ease-in-out duration-500 hover:shadow-lg`}
			>
				<div className="flex items-center gap-8">
					<div>
						<img src={company.profile_picture} className="w-64" alt="" />
					</div>
					<div>
						<h1 className="text-2xl lg:text-3xl font-bold mt-4 text-gray-custom">{company.nama_perusahaan}</h1>
						<h2 className="text-lg lg:text-xl text-gray-400 font-bold mt-1">{company.bidang_perusahaan ?? '-'}</h2>
						<h2 className="text-lg lg:text-xl text-gray-400 mt-3">
							{isEmpty(company.village) ? (
								<>-</>
							) : (
								<p>
									<span className="capitalize">
										{company.village.name.toLowerCase()} - {company.village.district.name.toLowerCase()} -{' '}
										{company.village.district.city.name.toLowerCase()}-{' '}
									</span>
									{company.village.district.city.province.name}
								</p>
							)}
						</h2>
					</div>
				</div>
				<div className="border-2 border-gray-200 rounded-full my-4"></div>
				<div className="my-2">
					<h1 className="font-bold text-xl lg:text-2xl text-gray-custom">Deskripsi Perusahaan</h1>
					<p className="mt-2">{company.deskripsi ?? '-'}</p>
				</div>
			</div>
		</Link>
	);
}

export default CompanyCard;
