import { Modal } from "antd";
import React, { useRef, useState } from "react";
import ReactQuill from "react-quill";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { setDone, setLoading } from "../../../../store/loading";
import axiosConfig from "../../../../utils/axiosConfig";
import { API_ENDPOINT } from "../../../../utils/config";
import { ConfirmModal } from "../../../reusable/PopupModal";
import { Toast } from "../../../reusable/Toast";

const formats = [
  "background",
  "bold",
  "color",
  "font",
  "code",
  "italic",
  "link",
  "size",
  "strike",
  "script",
  "underline",
  "blockquote",
  "header",
  "indent",
  "list",
  "align",
  "direction",
  "code-block",
  "formula",
];

function ModalLamar({ modalLamar, setModalLamar, job, bookmark, setBookmark }) {
  const user = useSelector((state) => state.user);
  const [sendLamaran, setSendLamaran] = useState(false);
  const editorRef = useRef(null);

  // file upload
  const [file, setFile] = useState(null);
  const fileInput = useRef(null);
  const dispatch = useDispatch();
  const [suratLamarEditor, setSuratLamarEditor] = useState("");

  const handleClickUploadFile = () => {
    fileInput.current.click();
  };
  const inputFile = () => {
    setFile(fileInput.current.files[0]);
  };

  const onClose = () => {
    setModalLamar(false);
  };

  const onLamar = async () => {
    const confirm = await ConfirmModal.fire({
      icon: "question",
      iconColor: "#31AAE0",
      title: "Apakah Kamu Yakin ingin melamar pada posisi ini?",
      confirmButtonColor: "#31AAE0",
      customClass: {
        title: "text-cxl font-bold text-center text-gray-custom",
      },
    });
    if (confirm.isConfirmed) {
      dispatch(setLoading());
      try {
        const formData = new FormData();
        formData.append("job_id", job.id);
        formData.append("pesan_lamaran", suratLamarEditor);
        if (file) {
          formData.append("resume", file);
        }
        formData.append("job_id", job.id);
        const response = await axiosConfig.post(
          `${API_ENDPOINT}/pencaker/lamaran`,
          formData
        );
        setSendLamaran(true);
      } catch (error) {
        Toast.fire({
          icon: "error",
          iconColor: "#EA4735",
          title: error.response?.data.info ?? "Terjadi Suatu Error",
        });
      } finally {
        dispatch(setDone());
      }
    }
  };
  const onBookmark = async () => {
    try {
      const response = await axiosConfig.post(
        `${API_ENDPOINT}/pencaker/bookmark`,
        { job_id: job.id }
      );
      setBookmark(!bookmark);
    } catch (error) {
      Toast.fire({
        icon: "error",
        iconColor: "#EA4735",
        title: error.response?.data.info ?? "Terjadi Suatu Error",
      });
    }
  };

  return (
    <Modal
      visible={modalLamar !== false}
      onCancel={onClose}
      onOk={onClose}
      title={null}
      closeIcon={<></>}
      footer={[]}
    >
      {sendLamaran ? (
        <div>
          <div className="cursor-pointer" onClick={onClose}>
            <img src="/images/lowongan/cross-gray.svg" alt="" className="w-8" />
          </div>
          <div className="flex flex-col items-center mt-6">
            <h1 className="font-bold text-c2xl">Lamaran Berhasil Terkirim</h1>
            <h3 className="text-cxl my-2">
              Tunggu respon perekrut di email/sms kamu
            </h3>
            <div className="my-4">
              <img
                src="/images/lowongan/file-sent.svg"
                className="w-40"
                alt=""
              />
            </div>
          </div>
          <Link to={"/lowongan/status-lamaran"}>
            <button className="w-full bg-secondary text-white text-xl font-bold rounded-lg p-5">
              OK, ke Status Lamaran
            </button>
          </Link>
        </div>
      ) : (
        <div>
          <div className="flex justify-between">
            <div>
              <img
                src={
                  job.company.profile_picture ??
                  "/images/profile-default-company.png"
                }
                className="w-20"
                alt=""
              />
            </div>
            <div className="cursor-pointer" onClick={onClose}>
              <img
                src="/images/lowongan/cross-gray.svg"
                alt=""
                className="w-8"
              />
            </div>
          </div>
          <div className="my-8 text-lg">
            <h2>Kamu akan melamar :</h2>
            <h1 className="my-2">
              ke{" "}
              <span className="font-bold text-cxl">
                {job.company.nama_perusahaan}
              </span>
            </h1>
            <h2>
              sebagai{" "}
              <span className="font-bold text-cxl">{job.posisi_pekerjaan}</span>
            </h2>
          </div>
          <div className="my-8">
            <div className="font-bold">CV (WAJIB)</div>
            <div>
              <button
                className="w-full my-4 py-6 px-8 flex justify-between items-center border-2 border-dashed border-primary bg-primary bg-opacity-10 rounded-xl cursor-pointer"
                onClick={() => handleClickUploadFile()}
              >
                {user.data.user_meta?.resume && file == null ? (
                  <div className="text-lg">Sudah Terupload pada profile</div>
                ) : (
                  <div className="text-lg">
                    {file == null ? "Upload CV" : "Sudah Terupload"}
                  </div>
                )}
                <img src="/images/file-people.svg" className="w-6" alt="" />
              </button>
              <input
                type="file"
                className="hidden"
                accept="application/pdf"
                ref={fileInput}
                onChange={() => inputFile()}
              />
              <h3 className="text-gray-400 text-sm italic mt-1">
                format PDF (max. 5MB).
                <br />
                Upload sekali saja dan kamu dapat menggunakannya untuk lamaran
                berikutnya.
              </h3>
            </div>
            <div className="mt-6">
              <div className="font-bold">Surat Lamaran</div>
              <p className="mb-3 text-gray-500 text-sm">
                Jelaskan mengapa Anda layak untuk direkrut pada pekerjaan ini
              </p>

              <ReactQuill
                modules={{
                  toolbar: [
                    [{ header: [1, 2, 3, 4, 5, false] }],
                    ["bold", "italic", "underline"],
                    ["code-block"],
                  ],
                }}
                formats={formats}
                theme="snow"
                value={suratLamarEditor}
                onChange={(value) => setSuratLamarEditor(value)}
              />
            </div>
          </div>
          <div className="my-6">
            <button
              className="w-full rounded-lg text-center bg-secondary font-bold text-white border border-secondary py-3 my-2 text-cbase"
              onClick={onLamar}
            >
              Lamar Sekarang
            </button>
            {!bookmark && (
              <button
                onClick={onBookmark}
                className={`w-full flex items-center justify-center gap-4 rounded-lg text-center bg-white font-bold text-secondary border border-secondary py-3 my-2 text-cbase`}
              >
                <div>
                  <img
                    src="/images/lowongan/bookmark-outline-orange.svg"
                    className="h-6"
                    alt=""
                  />
                </div>
                <div>Simpan</div>
              </button>
            )}
          </div>
        </div>
      )}
    </Modal>
  );
}

export default ModalLamar;
