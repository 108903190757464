import { Checkbox, Form } from 'antd';
import axios from 'axios';
import { Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { createSearchParams, Link, useNavigate, useSearchParams } from 'react-router-dom';
import * as Yup from 'yup';
import { setDone, setLoading } from '../../../store/loading';
import { setUser } from '../../../store/user';
import { API_ENDPOINT } from '../../../utils/config';
import { getErrorValue } from '../../../utils/getErrors';
import headers from '../../../utils/headers';
import FormItem from '../../reusable/FormItem';
import Input from '../../reusable/Input';
import SubmitButton from '../../reusable/SubmitButton';
import { Toast } from '../../reusable/Toast';

const validationSchema = Yup.object().shape({
	name: Yup.string().required('Nama wajib diisi'),
	notel: Yup.number().typeError('Pastikan anda memasukan angka').required('No Handphone wajib di isi'),
	email: Yup.string().email('Email tidak valid').required('Email wajib diisi'),
	password: Yup.string().required('Password wajib diisi'),
	password_confirmation: Yup.string().required('Konfirmasi Password wajib diisi'),
	agree_terms: Yup.boolean().oneOf([true], 'Wajib diisi'),
});

function Register() {
	const initialState = {
		name: '',
		email: '',
		notel: '',
		password: '',
		password_confirmation: '',
		agree_terms: false,
	};

	const [input, setInput] = useState(initialState);
	const [searchParams, setSearchParams] = useSearchParams();
	const [errorMessage, setErrorMessage] = useState({});
	const dispatch = useDispatch();
	const navigate = useNavigate();

	useEffect(() => {
		if (searchParams.get('referral_code') !== 'null') {
			sessionStorage.setItem('referral_code', searchParams.get('referral_code'));
		}
	}, []);

	const onSubmit = async (values, { resetForm }) => {
		try {
			dispatch(setLoading());
			const referralCode =
				sessionStorage.getItem('referral_code') !== 'null' ? sessionStorage.getItem('referral_code') : null;
			const response = await axios.post(
				`${API_ENDPOINT}/pencaker/register`,
				{ ...values, referral_code: referralCode },
				{ headers }
			);
			Toast.fire({
				icon: 'success',
				title: response.data.info,
				iconColor: '#17afe3',
			});
			setErrorMessage({});
			if (response.data.data.is_verify) {
				navigate({
					pathname: '/verification',
					search: createSearchParams({ id: response.data.data.user.id }).toString(),
				});
			} else {
				dispatch(setUser(response.data.data));
				navigate({
					pathname: '/isi-profile/1',
				});
			}
		} catch (error) {
			console.log(error);
			await Toast.fire({
				icon: 'error',
				iconColor: '#EA4735',
				title: error.response?.data.info ?? 'Terjadi Suatu Error',
			});
			setErrorMessage(error.response?.data?.data?.errors);
		} finally {
			dispatch(setDone());
		}
	};

	return (
		<div className="antialiased text-gray-custom flex flex-col text-gray">
			<nav className="items-center justify-between flex-wrap bg-teal w-11/12 mx-auto py-6 hidden lg:flex">
				<Link to="/">
					<div className="w-40 cursor-pointer">
						<img layout="responsive" width={180} height={55} src="/images/maisyah.svg" alt="" />
					</div>
				</Link>
				<div className="flex gap-6 items-center text-xl">
					<div className="">Sudah punya akun?</div>
					<Link to="/login">
						<div className="border-2 rounded-xl text-secondary border-secondary font-medium py-3 px-8">Login</div>
					</Link>
				</div>
			</nav>
			<nav className="flex flex-col gap-2 w-10/12 mx-auto mt-24 mb-6 lg:hidden">
				<div className="text-3xl font-bold">Daftar</div>
				<div className="text-lg">
					Sudah punya akun?{' '}
					<Link to="/login">
						<span className="text-secondary">Login.</span>
					</Link>
				</div>
			</nav>
			<div className="bg-cover bg-top bottom-0 w-full lg:bg-wave-big ">
				<div className="bg-white rounded-lg lg:drop-shadow-xl lg:p-12 w-10/12 lg:w-144 mx-auto text-lg mb-20 lg:my-20 ">
					<h1 className="font-bold text-center mb-8 text-3xl hidden lg:block">
						Selamat Datang di <span className="text-primary">Maisyah.id</span>
					</h1>
					<Formik initialValues={input} enableReinitialize validationSchema={validationSchema} onSubmit={onSubmit}>
						{({
							isSubmitting,
							handleSubmit,
							handleBlur,
							handleChange,
							errors,
							touched,
							values,
							setFieldValue,
							setFieldTouched,
							isValid,
							dirty,
						}) => (
							<Form onFinish={handleSubmit} layout="vertical">
								{/* NAMA LENGKAP */}
								<FormItem
									label="Nama Lengkap"
									error={getErrorValue(errors.name, errorMessage?.name)}
									touched={touched.name}
								>
									<Input
										onChange={handleChange}
										onBlur={handleBlur}
										value={values.name}
										name="name"
										placeholder="Masukkan nama lengkap anda"
										image={'/images/user.png'}
									/>
								</FormItem>
								{/* EMAIL */}
								<FormItem
									label="Email"
									error={getErrorValue(errors.email, errorMessage?.email)}
									touched={touched.email}
								>
									<Input
										onChange={handleChange}
										onBlur={handleBlur}
										value={values.email}
										name="email"
										placeholder="Masukkan email anda"
										image={'/images/email.png'}
									/>
								</FormItem>
								{/* NOTEL */}
								<FormItem
									label="Nomor HP"
									error={getErrorValue(errors.notel, errorMessage?.notel)}
									touched={touched.notel}
								>
									<Input
										onChange={handleChange}
										onBlur={handleBlur}
										value={values.notel}
										name="notel"
										placeholder="Masukkan no. HP anda"
										image={'/images/mobile.png'}
									/>
								</FormItem>
								{/* Password */}
								<FormItem
									label="Password"
									error={getErrorValue(errors.password, errorMessage?.password)}
									touched={touched.password}
								>
									<Input
										onChange={handleChange}
										onBlur={handleBlur}
										value={values.password}
										name="password"
										placeholder="Masukkan password anda"
										image={'/images/password.png'}
										type="password"
									/>
								</FormItem>
								{/* Confirmation Password */}
								<FormItem
									label="Ulangi Password"
									error={getErrorValue(errors.password_confirmation, errorMessage?.password_confirmation)}
									touched={touched.password_confirmation}
								>
									<Input
										onChange={handleChange}
										onBlur={handleBlur}
										value={values.password_confirmation}
										name="password_confirmation"
										placeholder="Ketik Ulang password anda"
										image={'/images/password.png'}
										type="password"
									/>
								</FormItem>

								<div className="my-8 flex gap-2">
									<div>
										<Checkbox
											onChange={() => setFieldValue('agree_terms', !values.agree_terms)}
											onBlur={handleBlur}
											value={values.agree_terms}
											checked={values.agree_terms}
											name="reseller"
										></Checkbox>
									</div>
									<div className="">
										Dengan mendaftar, saya telah membaca dan menyetujui
										<span className="text-secondary font-bold">
											{' '}
											<Link to={'/syarat-ketentuan'}>Ketentuan Penggunaan dan Kebijakan Privasi</Link>{' '}
										</span>
										Maisyah.id.
									</div>
								</div>

								<SubmitButton className="py-3 mb-4" dirty={dirty} isValid={isValid} isSubmitting={isSubmitting} />
								{/* <button className="lg:w-full border-2 border-gray font-bold py-3 rounded-xl flex justify-center items-center gap-2 lg:text-xl mb-4">
									<div>
										<img src="/images/google.png" alt="" className="w-6 mr-2" />
									</div>
									<div>Masuk dengan Google</div>
								</button> */}
								<a
									href="https://perusahaan.maisyah.id/register"
									type="button"
									className="w-full border-2 border-secondary font-bold py-3 rounded-xl text-secondary flex justify-center items-center gap-2 lg:text-xl"
								>
									<div>Daftar Sebagai Perusahaan</div>
									<div>
										<img src="/images/info.png" alt="" className="w-5" />
									</div>
								</a>
							</Form>
						)}
					</Formik>
				</div>
			</div>
			<div className="h-48 w-full bg-wave-small bg-cover bg-top lg:hidden"></div>
		</div>
	);
}

export default Register;
