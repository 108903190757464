import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import isAuthenticated from '../../utils/isAuthenticated';

function AuthenticatedRoutes({ children }) {
	if (!isAuthenticated()) {
		return <Navigate to="/login" replace />;
	}

	return children ?? <Outlet />;
}

export default AuthenticatedRoutes;
