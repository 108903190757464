import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import { setDone, setLoading } from '../../store/loading';
import axiosConfig from '../../utils/axiosConfig';
import { API_ENDPOINT } from '../../utils/config';

function ForgotPassword() {
	const dispatch = useDispatch();

	const [email, setEmail] = useState('');

	const onSubmit = async () => {
		try {
			dispatch(setLoading());
			const response = await axiosConfig.post(`${API_ENDPOINT}/pencaker/email/reset`, {
				email,
			});
			dispatch(setDone());
			Swal.fire({
				icon: 'success',
				title: 'Reset Password Terkirim',
				text: 'Link Reset Password Berhasil Terkirim. Silahkan Cek Email Anda',
				iconColor: '#17afe3',
			});
			setEmail('');
		} catch (error) {
			console.log(error);
			dispatch(setDone());
			Swal.fire({
				icon: 'error',
				title: 'Reset Password Gagal',
				text: error.response.data.info ?? 'Link Reset Password Gagal dikirim',
			});
		}
	};

	return (
		<div className="antialiased h-screen flex flex-col text-gray-custom">
			{/* HEADER  */}
			<nav className="hidden lg:flex items-center justify-between flex-wrap bg-teal w-11/12 mx-auto py-6">
				<Link to="/">
					<div className="w-40 cursor-pointer">
						<img layout="responsive" width={180} height={55} src="/images/maisyah.svg" alt="" />
					</div>
				</Link>
			</nav>
			<nav className="flex flex-col gap-2 w-10/12 mx-auto mt-24 mb-5 lg:hidden">
				<div className="text-3xl font-bold">Lupa Password</div>
				<div className="">Masukkan Email yang kamu gunakan ketika mendaftar</div>
			</nav>
			<div
				className="flex-grow flex flex-col items-center justify-center w-full bg-contain bg-no-repeat bg-bottom "
				style={{ backgroundImage: "url('/images/bg-small.png')" }}
			>
				<div className="md:w-136 2xl:w-1/3 flex flex-col gap-4 lg:rounded-lg lg:drop-shadow-lg lg:bg-white lg:p-16 -mt-48 lg:-mt-16">
					<div className="hidden lg:block">
						<div className="text-center text-3xl font-bold">Lupa Password</div>
						<div className="text-center font-light mb-8">Masukkan Email yang kamu gunakan ketika mendaftar</div>
					</div>
					<div id="otp" className="flex justify-center text-center mb-8">
						<input
							className="border-b border-sky-300 text-center rounded outline-none py-2 focus:border-primary focus:border-b-2 text-xl"
							type="text"
							onChange={e => setEmail(e.target.value)}
							value={email}
						/>
					</div>
					<button
						onClick={onSubmit}
						className="w-full flex justify-center rounded-xl bg-secondary font-bold py-5 lg:py-4 hover:bg-opacity-90 text-white mb-4 text-lg cursor-pointer"
					>
						Submit
					</button>
					<div className="text-center"></div>
				</div>
			</div>
		</div>
	);
}

export default ForgotPassword;
