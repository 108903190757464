/* eslint-disable default-case */
import { Empty, Pagination } from 'antd';
import { isEmpty } from 'lodash';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { createSearchParams, Link, useNavigate, useSearchParams } from 'react-router-dom';
import { setDone, setLoading } from '../../../store/loading';
import axiosConfig from '../../../utils/axiosConfig';
import { API_ENDPOINT } from '../../../utils/config';
import generateSeed from '../../../utils/generateSeed';
import Footer from '../../reusable/Footer';
import { Toast } from '../../reusable/Toast';
import CompanyCard from './_partials/CompanyCard';
import JobCard from './_partials/JobCard';
import MobileNav from './_partials/MobileNav';
import Navbar from './_partials/Navbar';
import SideMenu from './_partials/SideMenu';

const ListCard = ({ jobs, companies }) => {
	if (jobs.data?.length === 0 && companies.data?.length === 0) {
		return <Empty description="Tidak Ada Pekerjaan" />;
	} else if (jobs.data?.length === 0) {
		return (
			<div className="grid gap-8 my-6">
				{companies.data?.map((company, index) => (
					<CompanyCard company={company} />
				))}
			</div>
		);
	} else {
		return (
			<div className="grid lg:grid-cols-2 gap-10 my-6">
				{jobs.data?.map((job, index) => (
					<JobCard job={job} key={index} />
				))}
			</div>
		);
	}
};

function Lowongan() {
	const [jobs, setJobs] = useState({});
	const [companies, setCompanies] = useState({});
	const [sort, setSort] = useState(null);
	const [search, setSearch] = useState('');
	const [paginationSize, setPaginationsize] = useState(10);
	const dispatch = useDispatch();
	const [searchParams, setSearchParams] = useSearchParams();
	const navigate = useNavigate();

	const [filter, setFilter] = useState({ type: [], pengalaman: [] });

	const [menu, setMenu] = useState({
		sort: false,
		fit: false,
		type: false,
		gender: false,
		experience: false,
		city: false,
	});

	const fetchJobs = async () => {
		try {
			dispatch(setLoading());
			let params = {
				type: filter.type.length > 0 ? filter.type : null,
				pengalaman: filter.pengalaman.length > 0 ? filter.pengalaman : null,
				search: searchParams.get('search') == null ? '' : searchParams.get('search'),
				page: searchParams.get('page') == null ? '' : searchParams.get('page'),
				limit: paginationSize,
			};
			if (sort) {
				params.orderBy = sort;
			} else {
				params.seed = generateSeed();
			}
			const response = await axiosConfig.get(`${API_ENDPOINT}/pencaker/jobs`, {
				params,
			});
			setJobs(response.data.data.jobs);
			setCompanies(response.data.data.companies);
		} catch (error) {
			console.log(error);
			Toast.fire({
				icon: 'error',
				iconColor: '#EA4735',
				title: error.response?.data.info ?? 'Terjadi Suatu Error',
			});
		} finally {
			dispatch(setDone());
		}
	};

	const onEnterSearch = event => {
		if (event.key === 'Enter') {
			navigate({
				pathname: '/lowongan',
				search: createSearchParams({ search }).toString(),
			});
		}
	};

	useEffect(() => {
		fetchJobs();
	}, [filter, sort, searchParams, paginationSize]);

	const onSearch = () => {
		navigate({
			pathname: '/lowongan',
			search: createSearchParams({ search }).toString(),
		});
	};

	const setDisplayMenu = name => {
		switch (name) {
			case 'sort':
				setMenu(previousData => ({ ...previousData, sort: !previousData.sort }));
				break;
			case 'fit':
				setMenu(previousData => ({ ...previousData, fit: !previousData.fit }));
				break;
			case 'type':
				setMenu(previousData => ({ ...previousData, type: !previousData.type }));
				break;
			case 'gender':
				setMenu(previousData => ({ ...previousData, gender: !previousData.gender }));
				break;
			case 'experience':
				setMenu(previousData => ({ ...previousData, experience: !previousData.experience }));
				break;
			case 'city':
				setMenu(previousData => ({ ...previousData, city: !previousData.city }));
				break;
		}
	};

	const onChangeCheckbox = e => {
		if (e.target.checked) {
			setFilter({ ...filter, [e.target.name]: [e.target.value, ...filter[e.target.name]] });
		} else {
			setFilter({ ...filter, [e.target.name]: filter[e.target.name].filter(item => item !== e.target.value) });
		}
	};

	const handleChangePage = (page, pageSize) => {
		setPaginationsize(pageSize);
		navigate({
			pathname: '/lowongan',
			search: createSearchParams({
				page,
				search: searchParams.get('search') == null ? '' : searchParams.get('search'),
			}).toString(),
		});
	};

	return (
		<div className="text-gray-custom">
			<MobileNav />
			<header
				className="m-8 py-8 bg-cover bg-bottom rounded-3xl hidden lg:block"
				style={{ backgroundImage: "url('/images/lowongan/header.png')" }}
			>
				<Navbar />

				<div className="text-white font-bold text-5xl text-center mt-20">Cari Lowongan Pekerjaan</div>
				<div className="flex justify-center my-10">
					<div className="bg-white p-2 w-1/2 rounded-lg flex justify-between shadow-xl min-w-fit mx-4">
						<div className="flex items-center w-8/12 my-2 ml-4">
							<div className="w-1/12">
								<img src="/images/lowongan/jobs.png" className="w-8" alt="" />
							</div>
							<div className="w-11/12">
								<input
									onKeyDown={onEnterSearch}
									type="text"
									className="outline-none text-lg w-full placeholder:text-gray-300 pl-4"
									placeholder="Nama pekerjaan atau perusahaan"
									onChange={e => setSearch(e.target.value)}
								/>
							</div>
						</div>
						{/* <div className="flex items-center w-4/12 border-l-2 border-gray border-opacity-25 pl-4 my-2">
							<div className="w-2/12">
								<img src="/images/lowongan/location.png" className="w-6" alt="" />
							</div>
							<div className="w-10/12">
								<input
									type="text"
									className="outline-none text-lg w-full placeholder:text-gray-200"
									placeholder="Semua lokasi"
									disabled={true}
								/>
							</div>
						</div> */}
						<div
							onClick={onSearch}
							className="flex cursor-pointer items-center w-4/12 rounded-xl bg-secondary py-4 px-2 justify-center gap-2"
						>
							<div className="">
								<img src="/images/lowongan/search.png" className="w-5" alt="" />
							</div>
							<div className="text-white font-semibold">Cari Pekerjaan</div>
						</div>
					</div>
				</div>
			</header>
			<header
				className="m-2 p-4 bg-cover bg-bottom rounded-3xl flex flex-col gap-4 items-center lg:hidden"
				style={{ backgroundImage: "url('/images/lowongan/header.png')" }}
			>
				<h1 className="text-white font-bold text-2xl">Cari Lowongan Pekerjaan</h1>
				<div className="bg-white rounded-2xl p-3 w-full max-w-xl">
					<div className="grid grid-cols-10 gap-4 w-full border-gray border-opacity-25 my-2">
						<div className="col-span-1 flex items-center justify-center">
							<img src="/images/lowongan/jobs.png" className="w-5" alt="" />
						</div>
						<div className="col-span-9">
							<input
								type="text"
								className="outline-none w-full placeholder:text-gray-200"
								placeholder="Pekerjaan atau keyword"
								onChange={e => setSearch(e.target.value)}
							/>
						</div>
					</div>
					{/* <div className="border w-full my-4"></div>
					<div className="grid grid-cols-10 gap-4 w-full border-gray border-opacity-25 my-2">
						<div className="col-span-1 flex items-center justify-center">
							<img src="/images/lowongan/location.png" className="w-5" alt="" />
						</div>
						<div className="col-span-9">
							<input
								type="text"
								className="outline-none w-full placeholder:text-gray-200"
								placeholder="Semua lokasi"
								disabled={true}
							/>
						</div>
					</div> */}
					<div
						onClick={onSearch}
						className="flex cursor-pointer items-center w-full rounded-xl bg-secondary py-4 justify-center gap-2 mt-6"
					>
						<div className="">
							<img src="/images/lowongan/search.png" className="w-5" alt="" />
						</div>
						<div className="text-white font-semibold">Cari Pekerjaan</div>
					</div>
				</div>
			</header>
			<main className="flex mx-8 my-10">
				<SideMenu
					menu={menu}
					setDisplayMenu={setDisplayMenu}
					onChangeCheckbox={onChangeCheckbox}
					sort={sort}
					setSort={setSort}
				/>
				<div className="border-r border-gray-custom border-opacity-30 mx-10 hidden lg:block"></div>
				<div className="w-full">
					<div className="flex items-center gap-4">
						<div className="flex items-center gap-2 p-3 rounded-lg bg-secondary bg-opacity-20">
							<div>
								<img src="/images/lowongan/compass-filled-orange.svg" alt="" className="h-8" />
							</div>
							<div className="text-secondary font-bold text">Explorer</div>
						</div>
						<Link to="/lowongan/bookmark">
							<div className="flex items-center gap-2 p-3 rounded-lg hover:bg-secondary hover:bg-opacity-20 cursor-pointer">
								<div>
									<img src="/images/lowongan/bookmark.png" alt="" className="h-8" />
								</div>
								<div className="font-bold text-zinc-400">Bookmark</div>
							</div>
						</Link>
					</div>

					<ListCard jobs={jobs} companies={companies} />
					<div className="flex justify-end">
						<Pagination
							onChange={handleChangePage}
							current={isEmpty(searchParams.get('page')) ? 1 : parseInt(searchParams.get('page'))}
							total={jobs.total}
						/>
					</div>
				</div>
			</main>
			<Footer />
		</div>
	);
}

export default Lowongan;
