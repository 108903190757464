import { Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setDone, setLoading } from '../../../store/loading';
import axiosConfig from '../../../utils/axiosConfig';
import { API_ENDPOINT } from '../../../utils/config';
import Footer from '../../reusable/Footer';
import Navbar from '../../reusable/Navbar';
import { Toast } from '../../reusable/Toast';
import EditProfileForm from './_partials/EditProfileForm';
import EditProfilePicture from './_partials/EditProfilePicture';
import EditTentangForm from './_partials/EditTentangForm';

const { Option } = Select;

function EditProfile() {
	const [data, setData] = useState({});
	const dispatch = useDispatch();

	const fetchProfile = async () => {
		try {
			dispatch(setLoading());
			const response = await axiosConfig.get(`${API_ENDPOINT}/pencaker/profile`);
			setData(response.data.data);
		} catch (error) {
			Toast.fire({
				icon: 'error',
				iconColor: '#EA4735',
				title: error.response?.data.info ?? 'Terjadi Suatu Error',
			});
		} finally {
			dispatch(setDone());
		}
	};
	useEffect(() => {
		fetchProfile();
	}, []);

	return (
		<div id="profile">
			<Navbar />
			<div className="border-t-2">
				<section className="w-10/12 mx-auto grid lg:grid-cols-12 mt-12 gap-4 border-b-2 pb-8">
					<EditProfilePicture data={data} fetchProfile={fetchProfile} />
					<div className="hidden lg:flex justify-center col-span-1">
						<div className="border h-full"></div>
					</div>
					<div className="lg:col-span-8">
						<EditProfileForm data={data} fetchProfile={fetchProfile} />
					</div>
				</section>
				<section className="mx-auto w-10/12 my-10">
					<EditTentangForm data={data} fetchProfile={fetchProfile} />
				</section>
			</div>
			<Footer />
		</div>
	);
}

export default EditProfile;
