import React, { useEffect, useState } from 'react';
import Popup from 'reactjs-popup';

function BannerPromo() {
	const [open, setOpen] = useState(false);
	const handleClose = () => {
		setOpen(false);
	};
	useEffect(() => {
		if (!sessionStorage.getItem('banner_promo')) {
			sessionStorage.setItem('banner_promo', true);
			setOpen(true);
		}
	}, []);

	return (
		<div>
			<Popup open={open} closeOnDocumentClick closeOnEscape onClose={handleClose}>
				<div className="relative">
					<button onClick={handleClose} className="absolute right-4 top-4 px-4 py-1 bg-white rounded-full font-bold">
						Close
					</button>
					<img src="/images/early-year-banner-promo.jpeg" className="w-full" alt="" />
				</div>
			</Popup>
		</div>
	);
}

export default BannerPromo;
