import React, { useState } from 'react';
import Verification from './Verification';
import VerificationFailed from './VerificationFailed';
import VerificationSuccess from './VerificationSuccess';

function VerificationIndex() {
	const [view, setView] = useState(false);
	const [info, setInfo] = useState('');

	if (view === 'success') {
		return <VerificationSuccess setView={setView} />;
	} else if (view === 'failed') {
		return <VerificationFailed info={info} setInfo={setInfo} setView={setView} />;
	} else {
		return <Verification setView={setView} setInfo={setInfo} />;
	}
}

export default VerificationIndex;
