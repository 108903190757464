import { isEmpty } from "lodash";
import { useEffect, useState } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import { Link, useNavigate, useParams } from "react-router-dom";
import axiosConfig from "../../../../utils/axiosConfig";
import { API_ENDPOINT } from "../../../../utils/config";
import { getTimeJob } from "../../../../utils/getTimeJob";
import isAuthenticated from "../../../../utils/isAuthenticated";
import numeral from "../../../../utils/numeralLocale";
import Footer from "../../../reusable/Footer";
import Navbar from "../../../reusable/Navbar";
import { Toast } from "../../../reusable/Toast";
import JobCard from "../../lowongan/_partials/JobCard";
import ModalLamar from "../_partials/ModalLamar";

function LowonganDetail() {
  const navigate = useNavigate();

  const { id } = useParams();
  const [modalLamar, setModalLamar] = useState(false);
  const [loading, setLoading] = useState(true);
  const [bookmark, setBookmark] = useState(false);
  const [data, setData] = useState({});

  const fetchJobDetail = async () => {
    try {
      setLoading(true);
      const response = await axiosConfig.get(
        `${API_ENDPOINT}/pencaker/jobs/${id}`
      );
      setData(response.data.data);
      if (response.data.data.job.is_bookmark) {
        setBookmark(true);
      }
    } catch (error) {
      console.log(error);
      Toast.fire({
        icon: "error",
        iconColor: "#EA4735",
        title: error.response?.data.info ?? "Terjadi Suatu Error",
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchJobDetail();
  }, [id]);

  const onBookmark = async () => {
    try {
      if (!isAuthenticated()) {
        navigate("/login");
        return;
      }
      if (bookmark) {
        const response = await axiosConfig.delete(
          `${API_ENDPOINT}/pencaker/bookmark`,
          { data: { job_id: id } }
        );
      } else {
        const response = await axiosConfig.post(
          `${API_ENDPOINT}/pencaker/bookmark`,
          { job_id: id }
        );
      }
      setBookmark(!bookmark);
    } catch (error) {
      console.log(error);
      Toast.fire({
        icon: "error",
        iconColor: "#EA4735",
        title: error.response?.data.info ?? "Terjadi Suatu Error",
      });
    }
  };

  const onCopy = () => {
    Toast.fire({
      icon: "success",
      title: "Berhasil salin link",
      iconColor: "#17afe3",
    });
  };

  const onLamar = (e) => {
    e.preventDefault();

    if (!isAuthenticated()) {
      navigate("/login");
      return;
    }
    setModalLamar(true);
  };
  return (
    !loading && (
      <div className="text-gray-custom">
        {modalLamar && (
          <ModalLamar
            modalLamar={modalLamar}
            setModalLamar={setModalLamar}
            bookmark={bookmark}
            setBookmark={setBookmark}
            job={data.job}
          />
        )}
        <Navbar />
        <main className="mb-48">
          <div className="bg-primary bg-opacity-10 text-csm">
            <div className="w-11/12 mx-auto hidden lg:flex gap-2 py-6">
              <Link to={"/lowongan"}>
                <p className="text-primary cursor-pointer">Cari lowongan</p>
              </Link>
              <div>/</div>
              <div>{data.job.posisi_pekerjaan}</div>
            </div>
          </div>
          <Link to={"/lowongan"}>
            <div className="ml-7 my-4 flex items-center gap-4 cursor-pointer lg:hidden">
              <img
                src="/images/lowongan-detail/arrow-left.png"
                alt=""
                className="w-6"
              />
              <p className="text-xl">Kembali</p>
            </div>
          </Link>
          <div className="w-full lg:w-11/12 mx-auto flex flex-col gap-8 lg:flex-row lg:gap-0 justify-center items-start lg:justify-between ">
            <div className="w-full lg:w-7/12 px-10 lg:px-0">
              <div className="flex gap-4 lg:gap-6 my-6">
                <div>
                  <img
                    src={
                      data.job.company.profile_picture ??
                      "/images/profile-default-company.png"
                    }
                    className="w-24"
                    alt=""
                  />
                </div>
                <div className="flex flex-col gap-2">
                  <div className="text-cxl font-bold">
                    {data.job.posisi_pekerjaan}
                  </div>
                  <Link to={`/company/${data.job.company.id}`}>
                    <div className=" text-clg text-primary cursor-pointer">
                      {data.job.company.nama_perusahaan}
                    </div>
                  </Link>
                </div>
              </div>
              <div className="flex flex-col gap-2 text-csm my-4">
                <div className="flex gap-4 items-center">
                  <div>
                    <img
                      src="/images/lowongan/building.png"
                      className="w-5"
                      alt=""
                    />
                  </div>
                  <div className="capitalize">
                    {isEmpty(data.job.district) ? (
                      <>{data.job.lokasi_display}</>
                    ) : (
                      <>
                        {data.job.district.name.toLowerCase()} -{" "}
                        {data.job.district.city.name.toLowerCase()} (
                        {data.job.lokasi_display})
                      </>
                    )}
                  </div>
                </div>
                <div className="flex gap-4 items-center">
                  <div>
                    <img
                      src="/images/lowongan/uang.png"
                      className="w-5"
                      alt=""
                    />
                  </div>
                  {data.job.is_display_salary ? (
                    <div>
                      Rp {numeral(data.job.batas_bawah_gaji).format("0,0")} - Rp{" "}
                      {numeral(data.job.batas_atas_gaji).format("0,0")} / bulan
                    </div>
                  ) : (
                    <div>Perusahaan tidak menampilkan gaji</div>
                  )}
                </div>
                <div className="flex gap-4 items-center">
                  <div>
                    <img
                      src="/images/lowongan/job.png"
                      className="w-5"
                      alt=""
                    />
                  </div>
                  <div>Pengalaman {data.job.pengalaman}</div>
                </div>
                <div className="flex gap-4 items-center">
                  <div>
                    <img
                      src="/images/lowongan-detail/time.png"
                      className="w-5"
                      alt=""
                    />
                  </div>
                  <div className="capitalize">{data.job.tipe_pekerjaan}</div>
                </div>
                <div className="flex flex-col-reverse lg:flex-row gap-6 text-csm mt-3">
                  <div className="flex gap-2 bg-primary bg-opacity-20 rounded-full py-3 items-center lg:w-1/3 justify-center">
                    <div>
                      <img
                        src="/images/lowongan-detail/board.png"
                        className="w-4"
                        alt=""
                      />
                    </div>
                    <div className="font-bold text-primary">Aktif Merekrut</div>
                  </div>
                  <ul className="list-disc flex items-center gap-8">
                    <li className="text-gray-500">
                      Tayang {getTimeJob(data.job.created_at)}
                    </li>
                    <li className="text-primary">
                      Diubah {getTimeJob(data.job.updated_at)}
                    </li>
                  </ul>
                </div>
                <button
                  onClick={onBookmark}
                  className={`lg:hidden font-bold w-full py-3 mt-4 border-2 rounded-2xl flex items-center gap-4 justify-center border-secondary ${
                    bookmark
                      ? "text-white bg-secondary"
                      : "text-secondary bg-white"
                  }`}
                >
                  <div>
                    <img
                      src={
                        bookmark
                          ? "/images/lowongan-detail/bookmark-white.svg"
                          : "/images/lowongan-detail/bookmark.png"
                      }
                      className="h-5"
                      alt=""
                    />
                  </div>
                  <div>Simpan</div>
                </button>
              </div>
              <div className="hidden lg:flex gap-6 items-center text-cbase">
                <button
                  className={`border-2 font-bold w-48 py-3 bg-secondary border-secondary rounded-2xl text-center ${
                    data.job.is_show
                      ? "bg-secondary text-white border-secondary"
                      : "bg-gray-200 text-gray-custom border-gray-custom cursor-not-allowed"
                  }`}
                  onClick={() => setModalLamar(true)}
                  disabled={!data.job.is_show}
                >
                  Lamar
                </button>
                <button
                  onClick={onBookmark}
                  className={`font-bold w-48 py-3 border-2 rounded-2xl flex items-center gap-4 justify-center border-secondary ${
                    bookmark
                      ? "text-white bg-secondary"
                      : "text-secondary bg-white"
                  }`}
                >
                  <div>
                    <img
                      src={
                        bookmark
                          ? "/images/lowongan-detail/bookmark-white.svg"
                          : "/images/lowongan-detail/bookmark.png"
                      }
                      className="w-4"
                      alt=""
                    />
                  </div>
                  <div>Simpan</div>
                </button>
                <CopyToClipboard text={window.location.href} onCopy={onCopy}>
                  <button className="font-bold text-secondary w-48 py-3 border-2 border-secondary rounded-2xl flex items-center gap-4 justify-center">
                    <div>
                      <img
                        src="/images/lowongan-detail/share.png"
                        className="w-5"
                        alt=""
                      />
                    </div>
                    <div>Bagikan</div>
                  </button>
                </CopyToClipboard>
              </div>
              <div className="w-full border-t-2 mt-8"></div>
              <div className="my-12">
                <div className="text-cxl font-bold mt-8 mb-4">
                  Deskripsi Pekerjaan
                </div>
                <p className="tracking-wide leading-7 text-cbase text-justify mb-8 whitespace-pre-line">
                  {data.job.job_desc}
                </p>
                {!isEmpty(data.job.job_responsibilities) && (
                  <div>
                    <div className="text-cxl font-bold">Tanggung Jawab</div>
                    <ul className="list-disc my-4 ml-8 flex flex-col gap-2 text-cbase">
                      {data.job.job_responsibilities.map(
                        (responsibility, index) => (
                          <li key={index}>{responsibility.name}</li>
                        )
                      )}
                    </ul>
                  </div>
                )}
                {!isEmpty(data.job.job_requirements) && (
                  <div>
                    <div className="text-cxl font-bold">
                      Kualifikasi / Persyaratan
                    </div>
                    <ul className="list-disc my-4 ml-8 flex flex-col gap-2 text-cbase">
                      {data.job.job_requirements.map((requirement, index) => (
                        <li key={index}>{requirement.name}</li>
                      ))}
                    </ul>
                  </div>
                )}
                <div>
                  <div className="text-cxl font-bold">Catatan Tambahan</div>
                  <p className="mt-2 text-cbase whitespace-pre-line">
                    {data.job.catatan ?? "Tidak ada catatan tambahan"}
                  </p>
                </div>
                <div>
                  <div className="text-cxl font-bold mt-8 mb-4">Skill</div>
                  <div className="flex flex-wrap gap-4">
                    {data.job.job_skills.map((skill, index) => (
                      <div
                        key={index}
                        className="text-primary font-bold p-4 bg-primary bg-opacity-20 rounded-xl text-cbase"
                      >
                        {skill.name}
                      </div>
                    ))}
                  </div>
                </div>
                <div>
                  <div className="text-cxl font-bold mt-8 mb-4">
                    Benefit / Keuntungan
                  </div>
                  <div className="grid lg:grid-cols-2 gap-6">
                    {data.job.job_benefits.map((benefit, index) => (
                      <div
                        key={index}
                        className="grid grid-cols-9 gap-4 items-center"
                      >
                        <div className="">
                          <img
                            src="/images/lowongan-detail/checklist-orange.svg"
                            alt=""
                          />
                        </div>
                        <div className="col-span-8 text-cbase">
                          {benefit.name}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div className="my-12 border-2 border-gray-custom border-opacity-20 p-4 lg:p-8 rounded-xl">
                <div className="font-bold text-cxl">Tentang Perusahaan</div>
                <div className="my-4 lg:m-4">
                  <div className="flex flex-col lg:flex-row gap-10">
                    <div>
                      <img
                        src={
                          data.job.company.profile_picture ??
                          "/images/profile-default-company.png"
                        }
                        className="w-20"
                        alt=""
                      />
                    </div>
                    <div className="flex flex-col gap-2">
                      <Link to={`/company/${data.job.company.id}`}>
                        <h1 className="text-cxl font-semibold text-primary cursor-pointer hover:underline">
                          {data.job.company.nama_perusahaan}
                        </h1>
                      </Link>
                      <div className="flex gap-6 text-gray-500 text-cbase">
                        <div>{data.job.company.bidang_perusahaan}</div>
                        <ul className="list-disc">
                          <li>{data.job.company.jumlah_karyawan} Karyawan</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="w-full whitespace-pre-line my-4">
                    {data.job.company.deskripsi}
                  </div>
                </div>
              </div>
            </div>
            <div className="w-full px-10 lg:px-0 lg:w-4/12">
              <h1 className="flex text-2xl font-bold my-8">Pekerjaan Lain</h1>
              <div className="flex flex-col gap-6">
                {data.another_job.map((job, index) => (
                  <JobCard job={job} key={index} />
                ))}
              </div>
            </div>
          </div>
        </main>
        <div className="sticky w-full bottom-0 py-4 lg:py-3 border-t-2 border-gray-custom border-opacity-10 bg-white">
          <div className="w-full lg:w-10/12 mx-auto flex justify-center items-center lg:justify-end gap-5 lg:gap-8 px-4 lg:px-0">
            <div className="hidden lg:block">
              <h1 className="font-bold">{data.job.posisi_pekerjaan}</h1>
              <h2 className="text-primary">
                {data.job.company.nama_perusahaan}
              </h2>
            </div>
            <div className="border-secondary self-stretch lg:border-none border-2 flex justify-center items-center lg:hidden gap-3 w-1/2 rounded-xl text-xl">
              <img
                src="/images/lowongan-detail/share.png"
                className="h-5"
                alt=""
              />
              <div className="text-secondary">Bagikan</div>
            </div>
            <div className="hidden lg:flex">
              <img
                src={
                  bookmark
                    ? "/images/lowongan/bookmark-filled-orange.svg"
                    : "/images/lowongan-detail/bookmark.png"
                }
                className="h-7 cursor-pointer"
                alt=""
                onClick={onBookmark}
              />
            </div>
            <button
              className={`border-2 font-bold w-1/2 lg:w-48 lg:px-12 rounded-xl py-2 text-xl lg:text-base ${
                data.job.is_show
                  ? "bg-secondary text-white border-secondary"
                  : "bg-gray-200 text-gray-custom border-gray-custom cursor-not-allowed"
              }`}
              onClick={onLamar}
              disabled={!data.job.is_show}
            >
              Lamar
            </button>
          </div>
        </div>

        <Footer />
      </div>
    )
  );
}

export default LowonganDetail;
