import Swal from 'sweetalert2';

export const ConfirmModal = Swal.mixin({
	showConfirmButton: true,
	showCancelButton: true,
	didOpen: confirm => {
		confirm.addEventListener('mouseenter', Swal.stopTimer);
		confirm.addEventListener('mouseleave', Swal.resumeTimer);
	},
	iconColor: 'red',
});

export const ResultModal = Swal.mixin({
	showConfirmButton: false,
	timer: 3000,
	timerProgressBar: true,
	didOpen: confirm => {
		confirm.addEventListener('mouseenter', Swal.stopTimer);
		confirm.addEventListener('mouseleave', Swal.resumeTimer);
	},
	
});
