import { Modal, Upload } from 'antd';
import ImgCrop from 'antd-img-crop';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { setDone, setLoading } from '../../../../store/loading';
import { setUserWithoutToken } from '../../../../store/user';
import axiosConfig from '../../../../utils/axiosConfig';
import { API_ENDPOINT } from '../../../../utils/config';
import { Toast } from '../../../reusable/Toast';

const getBase64 = file =>
	new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.readAsDataURL(file);
		console.log('test');

		reader.onload = () => resolve(reader.result);

		reader.onerror = error => reject(error);
	});

function EditProfilePicture({ data, fetchProfile }) {
	const [edit, setEdit] = useState(false);
	const [image, setImage] = useState(null);
	const [imageList, setImageList] = useState([]);
	const [previewVisible, setPreviewVisible] = useState(false);
	const [previewImage, setPreviewImage] = useState('');
	const dispatch = useDispatch();
	const onChange = ({ fileList: newFileList }) => {
		setImageList(newFileList);
	};

	const handlePreview = async file => {
		if (!file.url && !file.preview) {
			file.preview = await getBase64(file.originFileObj);
		}
		setPreviewImage(file.url || file.preview);
		setPreviewVisible(true);
	};

	const uploadPhoto = async () => {
		dispatch(setLoading());
		try {
			const formData = new FormData();
			formData.append('profile_picture', image);
			const response = await axiosConfig.post(`${API_ENDPOINT}/pencaker/profile/picture`, formData, {
				headers: { 'Content-Type': 'multipart/form-data' },
			});
			dispatch(setUserWithoutToken(response.data.data.user));
			setEdit(false);
			fetchProfile();
			Toast.fire({
				icon: 'success',
				title: response.data.info,
				iconColor: '#17afe3',
			});
		} catch (error) {
			console.log(error);
			Toast.fire({
				icon: 'error',
				iconColor: '#EA4735',
				title: error.response?.data.info ?? 'Terjadi Suatu Error' ?? 'terjadi suati error',
			});
		} finally {
			dispatch(setDone());
		}
	};
	const handleCancel = () => setPreviewVisible(false);
	return (
		<div className="lg:col-span-3" id="profile-picture">
			<h1 className="text-3xl font-bold text-gray-custom">Foto Profile</h1>
			<div className=" mt-4">
				{edit ? (
					<div className="flex items-center justify-center">
						<ImgCrop rotate zoom modalOk="Crop"  modalTitle="Edit Image">
							<Upload
								customRequest={({ onSuccess }) =>
									setTimeout(() => {
										onSuccess('Uploaded', null);
									}, 0)
								}
								maxCount={1}
								listType="picture-card"
								onChange={onChange}
								onPreview={handlePreview}
								fileList={imageList}
								beforeUpload={file => {
									setImage(file);
									return file;
								}}
							>
								{imageList.length < 1 && 'Upload Profile Picture'}
							</Upload>
						</ImgCrop>
					</div>
				) : (
					<div
						onClick={() => setPreviewVisible(true)}
						className="relative rounded-xl border-gray-600 flex items-center justify-center"
					>
						<div className="w-full h-full flex justify-center items-center absolute cursor-pointer text-xl font-bold text-transparent tracking-widest hover:text-white">
							Preview
						</div>
						<img
							style={{ width: 192 }}
							src={data.profile?.profile_picture ?? '/images/profile/bxs_user-circle.svg'}
							className=" rounded-xl w-full"
							alt=""
						/>
					</div>
				)}
				{/* Modal antara preview edit dan tidak memilki modal yang sama sehingga SRC nya perlu menggunakan ternary */}
				<Modal visible={previewVisible} title="Preview Image" footer={null} onCancel={handleCancel}>
					<img alt="example" style={{ width: 600 }} src={edit ? previewImage : data.profile?.profile_picture} />
				</Modal>
			</div>
			<div className="flex flex-col items-center gap-4 mt-12">
				{edit ? (
					<>
						<button
							className="text-secondary font-bold rounded-lg bg-white w-full py-4 border-2 border-secondary"
							onClick={() => setEdit(false)}
						>
							Batal
						</button>
						<button
							className="text-white font-bold rounded-lg bg-secondary w-full py-4 border-2 border-secondary"
							onClick={uploadPhoto}
						>
							Upload Foto
						</button>
					</>
				) : (
					<button
						className="text-white font-bold rounded-lg bg-secondary w-full py-4 border-2 border-secondary"
						onClick={() => setEdit(true)}
					>
						Change Foto
					</button>
				)}
			</div>
		</div>
	);
}

export default EditProfilePicture;
