import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { setDone, setLoading } from '../../../store/loading';
import axiosConfig from '../../../utils/axiosConfig';
import { API_ENDPOINT } from '../../../utils/config';
import Footer from '../../reusable/Footer';
import Navbar from '../../reusable/Navbar';
import { Toast } from '../../reusable/Toast';
import JobCard from '../lowongan/_partials/JobCard';

function CompanyDetail() {
	const { loading } = useSelector(state => state.loading);
	const dispatch = useDispatch();
	const [data, setData] = useState({});

	const { id } = useParams();

	const fetchCompany = async () => {
		try {
			dispatch(setLoading());
			const response = await axiosConfig.get(`${API_ENDPOINT}/pencaker/company/${id}`);
			setData(response.data.data.company);
		} catch (error) {
			console.log(error);
			Toast.fire({
				icon: 'error',
				iconColor: '#EA4735',
				title: error.response?.data?.info,
			});
		} finally {
			dispatch(setDone());
		}
	};
	useEffect(() => {
		fetchCompany();
	}, []);

	const openWebsite = url => {
		window.open(url, '_blank');
	};

	return (
		<div>
			<Navbar />
			<div className="mx-2 lg:w-5/6 lg:mx-auto bg-cover pb-8 relative bg-bottom rounded-3xl my-6 pt-40 block">
				<div>
					<img
						src="/images/lowongan/header.png"
						className="top-0 absolute -z-10 bg-cover"
						style={{ height: '50vh' }}
						alt=""
					/>
				</div>
				<div className="mx-4 lg:w-11/12 lg:mx-auto">
					<div className="w-full bg-white px-8 py-4 rounded-xl shadow-md">
						<div>
							<div className="w-44 h-44 rounded-full -mt-28 overflow-hidden">
								<img src={data.profile_picture} className="w-full h-full" alt="" />
							</div>
							<h1 className="text-2xl lg:text-3xl font-bold mt-4 text-gray-custom">{data.nama_perusahaan}</h1>
							<h2 className="text-lg lg:text-xl text-gray-400 font-bold mt-1">{data.bidang_perusahaan ?? '-'}</h2>
							<h2 className="text-lg lg:text-xl text-gray-400 mt-3">
								{isEmpty(data.village) ? (
									<>-</>
								) : (
									<p>
										<span className="capitalize">
											{data.village.name.toLowerCase()} - {data.village.district.name.toLowerCase()} -{' '}
											{data.village.district.city.name.toLowerCase()}-{' '}
										</span>
										{data.village.district.city.province.name}
									</p>
								)}
							</h2>
						</div>
						<div className="border-2 border-gray-200 rounded-full my-6"></div>
						<div className="my-4">
							<h1 className="font-bold text-xl lg:text-2xl text-gray-custom">Deskripsi Perusahaan</h1>
							<p className="mt-2">{data.deskripsi ?? '-'}</p>
						</div>
						<div className="my-4">
							<h1 className="font-bold text-xl lg:text-2xl text-gray-custom">Visi</h1>
							<p className="mt-2">{data.visi ?? '-'}</p>
						</div>
						<div className="my-4">
							<h1 className="font-bold text-xl lg:text-2xl text-gray-custom">Misi</h1>
							<ul className="mx-8 list-disc my-2 flex flex-col gap-1">
								{data.misi?.length > 0 ? data.misi.map((misi, index) => <li key={index}>{misi.text}</li>) : '-'}
							</ul>
						</div>
						<div className="my-4">
							<h1 className="font-bold text-xl lg:text-2xl text-gray-custom">Tagline</h1>
							<p className="mt-2">{data.tagline ?? '-'}</p>
						</div>
						<div className="border-2 border-gray-200 rounded-full my-6"></div>

						<div className="my-4 grid grid-cols-2 lg:grid-cols-6 gap-y-4">
							<p className="text-gray-500 lg:text-lg">Jumlah Karyawan</p>
							<p className="text-gray-custom lg:text-lg lg:col-span-2">{data.jumlah_karyawan ?? '-'}</p>
							<p className="text-gray-500 lg:text-lg">Alamat</p>
							<p className="text-gray-custom text-lg lg:col-span-2">{data.alamat ?? '-'}</p>
							<p className="text-gray-500 lg:text-lg">Situs</p>
							{data.website ? (
								<div className="text-gray-custom text-lg lg:col-span-2">{data.website}</div>
							) : (
								<p className="text-gray-custom lg:text-lg lg:col-span-2">-</p>
							)}
							<p className="text-gray-500 text-lg">Lowongan Aktif</p>
							<p className="text-gray-custom lg:text-lg lg:col-span-2">{data.active_jobs?.length}</p>
						</div>
					</div>
					<h1 className="mt-12 mb-6 font-bold text-2xl lg:text-3xl text-gray-custom">Lowongan yang Aktif</h1>

					<div className="grid lg:grid-cols-2 gap-8">
						{data.active_jobs?.map((job, index) => (
							<JobCard job={job} key={index} />
						))}
					</div>
				</div>
			</div>
			<Footer />
		</div>
	);
}

export default CompanyDetail;
