import { Checkbox, DatePicker, Form } from 'antd';
import { Formik } from 'formik';
import { isEmpty } from 'lodash';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { setDone, setLoading } from '../../../store/loading';
import axiosConfig from '../../../utils/axiosConfig';
import { API_ENDPOINT } from '../../../utils/config';
import { getErrorValue } from '../../../utils/getErrors';
import FormItem from '../../reusable/FormItem';
import Input from '../../reusable/Input';
import SubmitButton from '../../reusable/SubmitButton';
import { Toast } from '../../reusable/Toast';
import Navbar from './_partials/Navbar';

const validationSchema = Yup.object().shape({
	posisi: Yup.string().required('Posisi wajib diisi'),
	perusahaan: Yup.string().required('Perusahaan wajib diisi'),
	bio: Yup.string().nullable(),
});

function SecondProfile() {
	const initialState = {
		perusahaan: '',
		tgl_mulai: '',
		tgl_akhir: '',
		posisi: '',
		bio: '',
		current_job: false,
	};

	const [input, setInput] = useState(initialState);
	const [errorMessage, setErrorMessage] = useState({});
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const onSubmit = async (values, { resetForm }) => {
		try {
			let reqBody = {
				...values,
				tgl_mulai: values.tgl_mulai.format('YYYY-MM'),
				tgl_akhir: values.current_job ? null : values.tgl_akhir.format('YYYY-MM'),
			};
			dispatch(setLoading());

			const response = await axiosConfig.post(`${API_ENDPOINT}/pencaker/user-pekerjaan`, reqBody);
			Toast.fire({
				icon: 'success',
				title: response.data.info,
				iconColor: '#17afe3',
			});
			setErrorMessage({});
			dispatch(setDone());
			navigate({ pathname: '/isi-profile/3' });
		} catch (error) {
			console.log(error);
			Toast.fire({
				icon: 'error',
				iconColor: '#EA4735',
				title: error.response?.data.info ?? 'Terjadi Suatu Error',
			});
			setErrorMessage(error.response?.data?.data?.errors);
			dispatch(setDone());
		}
	};

	const onChangeTanggal = (date, dateString, key, setFieldValue) => {
		setFieldValue(key, date);
	};

	const onChangeCheckboxTglAkhir = (setFieldValue, values) => {
		setFieldValue('current_job', !values.current_job);
	};

	const disabledDateTglAkhir = (current, tglMulai) => {
		return current && current < tglMulai.endOf('day');
	};

	return (
		<div
			className="antialiased bg-white text-gray-custom bg-no-repeat bg-contain bg-center"
			style={{ backgroundImage: "url('/images/bg-isi.png')" }}
		>
			<Navbar href={'/isi-profile/3'} />
			<div className="flex items-center justify-center mb-16">
				<div className="w-full lg:w-2/5 marker:border-gray-custom rounded-lg box-border mt-16">
					{/* PROGRESS BAR */}
					<h1 className="text-sky-300 text-center mb-5 font-bold lg:font-normal text-xl">Step 2/4</h1>
					<div className="h-3 relative max-w-screen lg:max-w-2xl rounded-full overflow-hidden">
						<div className="w-full h-full bg-sky-100 absolute"></div>
						<div className="h-full bg-sky-500 absolute" style={{ width: '50%' }}></div>
					</div>
					<Formik initialValues={input} enableReinitialize validationSchema={validationSchema} onSubmit={onSubmit}>
						{({
							isSubmitting,
							handleSubmit,
							handleBlur,
							handleChange,
							errors,
							touched,
							values,
							setFieldValue,
							setFieldTouched,
							isValid,
							dirty,
						}) => (
							<Form onFinish={handleSubmit} layout="vertical">
								<h1 className="text-gray-500 text-center my-5 lg:mb-4 pt-5 text-lg">
									Apa pengalaman kerja terbaru anda?
								</h1>
								<div className="px-16">
									{/* POSISI */}
									<FormItem error={getErrorValue(errors.posisi, errorMessage?.posisi)} touched={touched.posisi}>
										<Input
											onChange={handleChange}
											onBlur={handleBlur}
											value={values.posisi}
											name="posisi"
											placeholder="Masukkan posisi anda"
										/>
									</FormItem>
									{/* PERUSAHAAN */}
									<FormItem
										error={getErrorValue(errors.perusahaan, errorMessage?.perusahaan)}
										touched={touched.perusahaan}
									>
										<Input
											onChange={handleChange}
											onBlur={handleBlur}
											value={values.perusahaan}
											name="perusahaan"
											placeholder="Masukkan perusahaan anda"
										/>
									</FormItem>
									{/* TANGGAL MULAI */}
									<FormItem label="Tanggal Mulai" className="mb-4">
										<DatePicker
											onChange={(date, dateString) => onChangeTanggal(date, dateString, 'tgl_mulai', setFieldValue)}
											picker="month"
											size="large"
											className="w-full"
										/>
									</FormItem>
									{/* TANGGAL AKHIR */}
									<FormItem label="Tanggal Akhir" className="mb-4">
										<DatePicker
											disabledDate={current => disabledDateTglAkhir(current, values.tgl_mulai)}
											onChange={(date, dateString) => onChangeTanggal(date, dateString, 'tgl_akhir', setFieldValue)}
											picker="month"
											size="large"
											className="w-full"
											disabled={isEmpty(values.tgl_mulai) || values.current_job}
										/>
									</FormItem>
									<div className="flex gap-2 mb-5 -mt-2">
										<Checkbox
											checked={values.current_job}
											onChange={() => onChangeCheckboxTglAkhir(setFieldValue, values)}
										/>
										<div className="text-gray-500">Saya masih bekerja di perusahaan ini</div>
									</div>
									{/* BIO */}
									<FormItem error={getErrorValue(errors.bio, errorMessage?.bio)} touched={touched.bio}>
										<Input
											onChange={handleChange}
											onBlur={handleBlur}
											value={values.bio}
											name="bio"
											placeholder="Masukkan bio anda"
											type="textarea"
											rows="4"
										/>
									</FormItem>
									{/*  TOMBOL DI BAWAH */}
									<div className="mt-24 mb-5 lg:mt-5 w-full lg:flex justify-between items-center">
										<Link to="/isi-profile/1">
											<div className="hidden lg:flex items-center gap-4 cursor-pointer">
												<img src="/images/arrow-orange.svg" className="h-4" alt="" />
												<p className="text-orange-400">Kembali</p>
											</div>
										</Link>
										<SubmitButton
											className="bg-secondary px-6 py-4 my-3 hover:bg-opacity-90 text-white font-bold border border-secondary rounded-lg cursor-pointer lg:w-auto"
											dirty={dirty}
											isValid={isValid}
											isSubmitting={isSubmitting}
										>
											Lanjutkan
										</SubmitButton>
										<Link to="/isi-profile/3" className="lg:hidden">
											<div className="rounded-xl border text-center justify-center items-center flex p-5 leading-none text-gray-400 border-gray-400 border-bold ">
												<p>Lewati langkah ini</p>
											</div>
										</Link>
									</div>
								</div>
							</Form>
						)}
					</Formik>
				</div>
			</div>
		</div>
	);
}

export default SecondProfile;
