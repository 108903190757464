import React, { useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { setDone, setLoading } from '../../../store/loading';
import axiosConfig from '../../../utils/axiosConfig';
import { API_ENDPOINT } from '../../../utils/config';
import { Toast } from '../../reusable/Toast';
import Navbar from './_partials/Navbar';

function FirstProfile() {
	const [pengalaman, setPengalaman] = useState(1);
	const [file, setFile] = useState(null);
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const fileInput = useRef(null);
	const handleClickButton = () => {
		fileInput.current.click();
	};
	const inputFile = () => {
		setFile(fileInput.current.files[0]);
	};

	const onSubmit = async () => {
		try {
			dispatch(setLoading());
			const formData = new FormData();
			formData.append('resume', file);
			formData.append('pengalaman', pengalaman);
			const response = await axiosConfig.post(`${API_ENDPOINT}/pencaker/user-meta`, formData);
			Toast.fire({
				icon: 'success',
				title: response.data.info,
				iconColor: '#17afe3',
			});
			dispatch(setDone());
			navigate({ pathname: '/isi-profile/2' });
		} catch (error) {
			console.log(error);
			Toast.fire({
				icon: 'error',
				iconColor: '#17afe3',
				title: error.response?.data.info ?? 'Terjadi Suatu Error',
			});
			dispatch(setDone());
		}
	};

	return (
		<div
			className="antialiased bg-white text-gray-custom bg-no-repeat bg-contain bg-center"
			style={{ backgroundImage: "url('/images/bg-isi.png')" }}
		>
			<Navbar href={'/isi-profile/2'} />
			<div className="flex items-center justify-center ">
				<div className="w-full lg:w-2/5 text-center marker:border-black rounded-lg box-border mt-16">
					<h1 className="text-sky-300 text-center mb-5 font-bold text-xl">Step 1/4</h1>
					<div className="h-3 relative max-w-screen-2xl lg:max-w-2xl rounded-full overflow-hidden">
						<div className="w-full h-full bg-sky-100 absolute"></div>
						<div className="h-full bg-sky-500 absolute" style={{ width: '25%' }}></div>
					</div>
					<h1 className="text-gray-400 text-center my-5 lg:mb-4 pt-5 text-lg">Yuk, bangun profil anda</h1>
					<div className="pr-5 pl-5 object-center">
						<div className="flex flex-col gap-4 mt-6">
							<div
								onClick={() => setPengalaman(1)}
								className={` border ${
									pengalaman ? 'border-secondary bg-secondary bg-opacity-20' : 'border-gray-400'
								} rounded-xl p-6 flex justify-between items-center cursor-pointer`}
							>
								<div className="text-lg">Saya punya pengalaman kerja</div>
								<img
									src={pengalaman ? '/images/checklist-orange.svg' : '/images/circle-gray.svg'}
									className="w-7"
									alt=""
								/>
							</div>
							<div
								className={`border ${
									pengalaman == false ? 'border-secondary bg-secondary bg-opacity-20' : 'border-gray-400'
								} rounded-xl p-6 flex justify-between items-stretch lg:items-center cursor-pointer`}
								onClick={() => setPengalaman(0)}
							>
								<div className="text-lg">Saya belum punya pengalaman kerja</div>
								<img
									src={pengalaman == false ? '/images/checklist-orange.svg' : '/images/circle-gray.svg'}
									className="w-7"
									alt=""
								/>
							</div>
						</div>
						<div className="border-t-2 my-6"></div>
						<div>
							<h1 className="text-gray-400 text-center mb-4 text-lg">Upload Resume anda</h1>
							<button
								className="w-full my-2 py-6 px-8 flex justify-between items-center border-2 border-dashed border-primary bg-primary bg-opacity-10 rounded-xl cursor-pointer"
								onClick={() => handleClickButton()}
							>
								<div className="text-lg">{file == null ? 'Upload Resume' : 'Sudah Terupload'}</div>
								<img src="/images/file-people.svg" className="w-6" alt="" />
							</button>
							<input
								type="file"
								className="hidden"
								ref={fileInput}
								accept="application/pdf"
								onChange={() => inputFile()}
							/>
							<h3 className="text-gray-400 text-sm italic mt-1">Upload resume dalam format PDF (max. 5MB)</h3>
						</div>
						<div className="mt-24 mb-5  w-full lg:flex justify-end items-center">
							<div></div>
							<div
								onClick={onSubmit}
								className="bg-secondary px-6 py-4 my-3 hover:bg-opacity-90 text-white font-bold border border-secondary rounded-lg cursor-pointer"
							>
								Lanjutkan
							</div>
							<Link to="/isi-profile/2" className="lg:hidden">
								<div className="rounded-xl border text-center justify-center items-center flex lg:hidden p-5 leading-none text-gray-400 border-gray-400 border-bold ">
									<p>Lewati langkah ini</p>
								</div>
							</Link>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default FirstProfile;
