import { LoadingOutlined } from '@ant-design/icons';
import { DatePicker, Form, Select, Spin } from 'antd';
import { Formik } from 'formik';
import { isEmpty } from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import { setDone, setLoading } from '../../../../store/loading';
import axiosConfig from '../../../../utils/axiosConfig';
import { API_ENDPOINT } from '../../../../utils/config';
import { getErrorValue } from '../../../../utils/getErrors';
import FormItem from '../../../reusable/FormItem';
import Input from '../../../reusable/Input';
import { Toast } from '../../../reusable/Toast';

const { Option } = Select;
const validationSchema = Yup.object().shape({
	name: Yup.string().required('Nama wajib diisi'),
	notel: Yup.number().typeError('Pastikan anda memasukan angka').required('No Handphone wajib di isi'),
	pengalaman: Yup.boolean().required('Nama wajib diisi'),
	bidang: Yup.string().required('Bidang wajib diisi'),
	tgl_lahir: Yup.string().required('Tanggal Lahir wajib diisi'),
	gender: Yup.string().required('Gender wajib diisi').oneOf(['L', 'P'], 'Wajib diisi'),
});
const loadingIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

function EditProfileForm({ data, fetchProfile }) {
	const initialState = {
		name: '',
		pengalaman: '',
		notel: '',
		kewarganegaraan: '',
		bidang: '',
		gender: '',
		tgl_lahir: '',
	};
	const [edit, setEdit] = useState(false);
	const [input, setInput] = useState(initialState);
	const [errorMessage, setErrorMessage] = useState({});

	const dispatch = useDispatch();

	const onSubmit = async values => {
		dispatch(setLoading());
		try {
			const formatTglLahir = values.tgl_lahir.format('DD-MM-YYYY');
			const response = await axiosConfig.post(`${API_ENDPOINT}/pencaker/profile/edit`, {
				...values,
				tgl_lahir: formatTglLahir,
			});
			setEdit(false);
			setErrorMessage({});
			fetchProfile();
			Toast.fire({
				icon: 'success',
				title: response.data.info,
				iconColor: '#17afe3',
			});
		} catch (error) {
			console.log(error);
			Toast.fire({
				icon: 'error',
				iconColor: '#EA4735',
				title: error.response?.data.info ?? 'Terjadi Suatu Error',
			});
			setErrorMessage(error.response?.data?.data?.errors);
		} finally {
			dispatch(setDone());
		}
	};

	useEffect(() => {
		if (!isEmpty(data)) {
			setInput({
				name: data.profile.name,
				pengalaman: data.profile.user_meta.pengalaman,
				notel: data.profile.notel,
				kewarganegaraan: data.profile.user_meta.kewarganegaraan,
				bidang: data.profile.user_meta.bidang ?? '',
				gender: data.profile.user_meta.gender ?? '',
				tgl_lahir:
					data.profile.user_meta.tgl_lahir !== null
						? moment(data.profile.user_meta.tgl_lahir, 'YYYY-MM-DD hh:mm:ss')
						: '',
			});
		}
	}, [data]);

	const onChangeTanggal = (date, dateString, key, setFieldValue) => {
		setFieldValue(key, date);
	};

	return (
		<Formik initialValues={input} enableReinitialize validationSchema={validationSchema} onSubmit={onSubmit}>
			{({
				isSubmitting,
				handleSubmit,
				handleBlur,
				handleChange,
				errors,
				touched,
				values,
				setFieldValue,
				setFieldTouched,
				isValid,
				dirty,
			}) => (
				<Form
					colon={false}
					labelAlign="left"
					layout={window.screen.width < 1200 ? 'vertical' : 'horizontal'}
					labelCol={{
						span: 6,
					}}
					wrapperCol={{
						span: window.screen.width < 1200 ? 24 : 20,
					}}
					onFinish={handleSubmit}
				>
					<div className="flex justify-between items-center mb-10">
						<div>
							<h1 className="text-3xl font-bold my-3">Profile</h1>
						</div>
						{edit ? (
							<div className="flex gap-4">
								<button
									className={`flex gap-4 items-center justify-center font-bold py-4 px-4 rounded-xl text-secondary
												`}
									onClick={() => setEdit(!edit)}
								>
									Cancel
								</button>
								<button
									className={`flex gap-4 items-center justify-center border py-4 px-4 rounded-xl ${
										isSubmitting
											? 'border-gray-custom bg-gray-custom border-opacity-20 bg-opacity-20 cursor-not-allowed'
											: 'bg-secondary border-secondary cursor-pointer'
									} `}
									type="submit"
									disabled={isSubmitting}
								>
									<img src={`/images/profile/save-white.svg`} alt="" className="w-4" />

									<p className="font-bold text-lg text-white">Simpan Perubahan</p>
									{isSubmitting && <Spin indicator={loadingIcon} />}
								</button>
							</div>
						) : (
							<button
								className={`flex gap-4 items-center justify-center border w-48 border-secondary py-4 rounded-xl cursor-pointer`}
								onClick={() => setEdit(true)}
							>
								<img src={`/images/profile/pencil-company-profile.svg`} alt="" className="w-8" />

								<p className="font-bold text-lg text-secondary">Edit Profile</p>
							</button>
						)}
					</div>
					{/* NAMA PERUSAHAAN */}
					<FormItem label="Nama" error={getErrorValue(errors.name, errorMessage?.name)} touched={touched.name}>
						<Input
							name="name"
							placeholder="Masukkan Nama anda"
							onChange={handleChange}
							onBlur={handleBlur}
							value={values.name}
							disabled={!edit}
						/>
					</FormItem>
					<FormItem label="Bidang" error={getErrorValue(errors.bidang, errorMessage?.bidang)} touched={touched.bidang}>
						<Input
							name="bidang"
							placeholder="Masukkan Bidang anda"
							onChange={handleChange}
							onBlur={handleBlur}
							value={values.bidang}
							disabled={!edit}
						/>
					</FormItem>
					<FormItem label="Nomor HP" error={getErrorValue(errors.notel, errorMessage?.notel)} touched={touched.notel}>
						<Input
							name="notel"
							placeholder="Masukkan Nomor Handphone anda"
							onChange={handleChange}
							onBlur={handleBlur}
							value={values.notel}
							disabled={!edit}
						/>
					</FormItem>
					<FormItem
						error={getErrorValue(errors.gender, errorMessage?.gender)}
						touched={touched.gender}
						label="Jenis Kelamin"
					>
						<Select
							name="gender"
							defaultValue={values.gender}
							style={{ width: '100%' }}
							size="large"
							value={values.gender}
							onBlur={() => setFieldTouched('gender')}
							onChange={value => {
								setFieldValue('gender', value);
							}}
							disabled={!edit}
						>
							<Option value="" disabled>
								Pilih Jenis Kelamin
							</Option>
							<Option value="L">Laki-Laki</Option>
							<Option value="P">Perempuan</Option>
						</Select>
					</FormItem>
					<FormItem
						label="Tanggal Lahir"
						className="mb-4"
						error={getErrorValue(errors.tgl_lahir, errorMessage?.tgl_lahir)}
						touched={touched.tgl_lahir}
					>
						<DatePicker
							onChange={(date, dateString) => onChangeTanggal(date, dateString, 'tgl_lahir', setFieldValue)}
							size="large"
							className="w-full"
							value={values.tgl_lahir}
							disabled={!edit}
						/>
					</FormItem>
					<FormItem
						label="Pengalaman"
						error={getErrorValue(errors.pengalaman, errorMessage?.pengalaman)}
						touched={touched.pengalaman}
					>
						<Select
							name="pengalaman"
							defaultValue={values.pengalaman}
							style={{ width: '100%' }}
							size="large"
							value={values.pengalaman}
							onBlur={() => setFieldTouched('pengalaman')}
							onChange={value => {
								setFieldValue('pengalaman', value);
							}}
							disabled={!edit}
						>
							<Option value="" disabled>
								Apakah anda Berpengalaman?
							</Option>
							<Option value={1}>Berpengalaman</Option>
							<Option value={0}>Belum Punya Pengalaman</Option>
						</Select>
					</FormItem>
					<FormItem
						label="Kewarganegaraan"
						error={getErrorValue(errors.kewarganegaraan, errorMessage?.kewarganegaraan)}
						touched={touched.kewarganegaraan}
					>
						<Input
							name="kewarganegaraan"
							placeholder="Masukkan Kewarganegaraan anda anda"
							onChange={handleChange}
							onBlur={handleBlur}
							value={values.kewarganegaraan}
							disabled={!edit}
						/>
					</FormItem>
				</Form>
			)}
		</Formik>
	);
}

export default EditProfileForm;
