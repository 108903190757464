import React from 'react';
import { Link } from 'react-router-dom';

function VerificationSuccess() {
	return (
		<div className="antialiased h-screen flex flex-col text-gray-custom">
			{/* HEADER  */}
			<nav className="hidden lg:flex items-center justify-between flex-wrap bg-teal w-11/12 mx-auto py-6">
				<Link to="/">
					<div className="w-40 cursor-pointer">
						<img layout="responsive" width={180} height={55} src="/images/maisyah.svg" alt="" />
					</div>
				</Link>
			</nav>
			<div
				className="flex-grow flex flex-col items-center justify-center w-full bg-contain bg-no-repeat bg-bottom"
				style={{ backgroundImage: "url('/images/bg-small.png')" }}
			>
				<div className="md:w-136 2xl:w-1/3 flex flex-col gap-4 rounded-lg lg:drop-shadow-lg lg:bg-white p-16 -mt-48 lg:-mt-16">
					<div className="text-center my-6 flex flex-col gap-2">
						<div className="mb-10 lg:mb-0">
							<div className="font-bold text-4xl lg:text-3xl">Email Berhasil Terverifikasi!</div>
							<div className="text-xl lg:text-lg text-gray-500 mt-3">Alhamdulillah, anda sudah terdaftar</div>
						</div>
						<div className="flex items-center justify-center mt-5">
							<img width={100} height={100} src="/images/check.png" alt="" />
						</div>
					</div>
					<div className="">
						<Link to="/isi-profile/1">
							<button
								className="w-full bg-secondary font-bold mb-10 px-5 lg:px-4 py-5 lg:py-3 place-content-center 
                            	hover:bg-opacity-90 text-white border border-secondary rounded tracking-wider"
							>
								OK, Login
							</button>
						</Link>
					</div>
				</div>
			</div>
		</div>
	);
}

export default VerificationSuccess;
