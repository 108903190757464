import { Form } from 'antd';
import { Formik } from 'formik';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import * as Yup from 'yup';
import FormItem from '../../components/reusable/FormItem';
import Input from '../../components/reusable/Input';
import SubmitButton from '../../components/reusable/SubmitButton';
import { Toast } from '../../components/reusable/Toast';
import { setDone, setLoading } from '../../store/loading';
import axiosConfig from '../../utils/axiosConfig';
import { API_ENDPOINT } from '../../utils/config';
import { getErrorValue } from '../../utils/getErrors';
import { ResultModal } from '../reusable/PopupModal';

const validationSchema = Yup.object().shape({
	password: Yup.string().required('Password wajib diisi'),
});

function ChangePassword() {
	const navigate = useNavigate();
	const [searchParams, setSearchParams] = useSearchParams();

	const token = searchParams.get('token');
	const email = searchParams.get('email');
	const initialState = {
		password: '',
		password_confirmation: '',
	};

	const dispatch = useDispatch();

	const [input, setInput] = useState(initialState);
	const [errorMessage, setErrorMessage] = useState({});

	const onSubmit = async (values, { resetForm }) => {
		try {
			dispatch(setLoading());
			const response = await axiosConfig.post(`${API_ENDPOINT}/pencaker/email/change-password`, {
				...values,
				token,
				email,
			});
			Toast.fire({
				icon: 'success',
				title: response.data.info,
				iconColor: '#17afe3',
			});
			setErrorMessage({});
			dispatch(setDone());
			navigate({ pathname: '/login' });
		} catch (error) {
			console.log(error);
			ResultModal.fire({
				icon: 'error',
				iconColor: '#EA4735',
				title: error.response?.data.info ?? 'Terjadi Suatu Error',
				text: error.response?.data.data,
			});
			dispatch(setDone());
			setErrorMessage(error.response?.data?.data?.errors);
		}
	};

	return (
		<div className="antialiased h-screen flex flex-col justify-between lg:justify-start text-gray-custom  bg-contain bg-no-repeat bg-bottom lg:bg-wave-small">
			{/* HEADER  */}
			<nav className="hidden lg:flex items-center justify-between flex-wrap bg-teal w-11/12 mx-auto py-6">
				<Link to="/">
					<div className="w-40 cursor-pointer">
						<img layout="responsive" width={180} height={55} src="/images/maisyah.svg" alt="" />
					</div>
				</Link>
			</nav>
			<div className="flex flex-col items-center justify-center w-full ">
				<div className="bg-white rounded-lg lg:drop-shadow-xl lg:p-12 w-10/12 lg:w-144 mx-auto text-lg mb-20 lg:my-20">
					<h1 className="font-bold text-center mb-8 text-3xl hidden lg:block">Ganti Password</h1>
					<Formik initialValues={input} enableReinitialize validationSchema={validationSchema} onSubmit={onSubmit}>
						{({
							isSubmitting,
							handleSubmit,
							handleBlur,
							handleChange,
							errors,
							touched,
							values,
							setFieldValue,
							setFieldTouched,
							isValid,
							dirty,
						}) => (
							<Form onFinish={handleSubmit} layout="vertical">
								{/* Password */}
								<FormItem
									label="Password"
									error={getErrorValue(errors.password, errorMessage?.password)}
									touched={touched.password}
								>
									<Input
										onChange={handleChange}
										onBlur={handleBlur}
										value={values.password}
										name="password"
										placeholder="Masukkan password anda"
										image={'/images/password.png'}
										type="password"
									/>
								</FormItem>
								{/* Password */}
								<FormItem
									label="Konfirmasi Password"
									error={getErrorValue(errors.password_confirmation, errorMessage?.password_confirmation)}
									touched={touched.password_confirmation}
								>
									<Input
										onChange={handleChange}
										onBlur={handleBlur}
										value={values.password_confirmation}
										name="password_confirmation"
										placeholder="Tulis ulang password anda"
										image={'/images/password.png'}
										type="password"
									/>
								</FormItem>
								<SubmitButton className="py-3 mb-4" dirty={dirty} isValid={isValid} isSubmitting={isSubmitting}>
									Ganti Password
								</SubmitButton>

								{/* <button className="w-full border text-xl border-gray-500 font-bold rounded-xl text-center flex items-center justify-center gap-4 py-3">
									<div>
										<img src="/images/google.png" alt="" className="w-6" />
									</div>
									<div>Masuk dengan Google</div>
								</button> */}
							</Form>
						)}
					</Formik>
				</div>
			</div>
			<div className="h-48 w-full bg-wave-small bg-cover bg-top lg:hidden"></div>
		</div>
	);
}

export default ChangePassword;
