import { Drawer, Dropdown, Menu } from 'antd';
import { isEmpty } from 'lodash';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSearchParams, Link, useNavigate } from 'react-router-dom';
import { deleteUser } from '../../store/user';
import axiosConfig from '../../utils/axiosConfig';
import { API_ENDPOINT } from '../../utils/config';

const menu = onLogout => (
	<Menu className="p-4">
		<Menu.Item key={'profil'} className="my-1 hover:bg-secondary hover:bg-opacity-10">
			<Link to={'/profile'}>
				<div className="flex items-center gap-4 py-2 rounded-xl">
					<div>
						<img src="/images/lowongan/profile-gray.svg" className="w-6" alt="" />
					</div>
					<div>Profile</div>
				</div>
			</Link>
		</Menu.Item>
		<Menu.Item key={'status_lamaran'} className="my-1 hover:bg-secondary hover:bg-opacity-10">
			<Link to={'/lowongan/status-lamaran'}>
				<div className="flex items-center  py-2 rounded-xl">
					<div className="flex items-center gap-4">
						<div>
							<img src="/images/lowongan/file-gray.svg" className="w-6" alt="" />
						</div>
						<div>Status Lamaran</div>
					</div>
					{/* <div className="text-red-custom font-bold flex ml-4">3</div> */}
				</div>
			</Link>
		</Menu.Item>
		<Menu.Item key={'bookmark'} className="my-1 hover:bg-secondary hover:bg-opacity-10">
			<Link to={'/lowongan/bookmark'}>
				<div className="flex items-center  py-2 rounded-xl">
					<div className="flex items-center gap-4">
						<div>
							<img src="/images/lowongan/bookmark-gray.svg" className="w-6" alt="" />
						</div>
						<div>Bookmarks</div>
					</div>
					{/* <div className="text-red-custom font-bold flex ml-4">3</div> */}
				</div>
			</Link>
		</Menu.Item>
		<Menu.Item key={'logout'} className="my-1 hover:bg-secondary hover:bg-opacity-10">
			<div className="flex items-center gap-4 py-2 rounded-xl" onClick={onLogout}>
				<div>
					<img src="/images/lowongan/logout-red.svg" className="w-5 ml-1" alt="" />
				</div>
				<div className="text-red-custom font-bold ml-1">Logout</div>
			</div>
		</Menu.Item>
	</Menu>
);
function Navbar() {
	const [nav, setNav] = useState(false);
	const [mobileNav, setMobileNav] = useState(false);
	const dispatch = useDispatch();
	const user = useSelector(state => state.user);

	const handleVisibleChange = flag => {
		setNav(flag);
	};
	const navigate = useNavigate();

	const pathName = window.location.pathname.split('/')[1];

	const onLogout = async () => {
		try {
			const response = await axiosConfig.post(`${API_ENDPOINT}/pencaker/logout`, {});
		} catch (e) {
			console.log(e);
		}
		dispatch(deleteUser());
		navigate('/login');
	};

	return (
		<div className="sticky top-0 bg-white z-10 shadow-sm lg:shadow-none">
			<header className="w-11/12 mx-auto grid grid-cols-12 items-center py-4 gap-2 ">
				<div className="col-span-10 lg:col-span-2 ">
					<Link to={'/'}>
						<div className="w-40 cursor-pointer">
							<img layout="responsive" width={180} height={55} src="/images/maisyah.svg" alt="" />
						</div>
					</Link>
				</div>
				<div className="hidden lg:flex col-span-5 gap-8">
					<Link to={'/'}>
						<div>
							<p className={`${isEmpty(pathName) && 'text-secondary font-bold'} cursor-pointer`}>Beranda</p>
							{isEmpty(pathName) && <div className="mt-0.5 border-t-4 border-secondary w-6 mx-auto"></div>}
						</div>
					</Link>
					<Link
						to={{
							pathname: '/',
							search: createSearchParams({ goto: 'layanan' }).toString(),
						}}
					>
						<div className="cursor-pointer">
							<p>Layanan</p>
							<div></div>
						</div>
					</Link>
					<Link to={'/lowongan'}>
						<div className={`cursor-pointer`}>
							<p className={pathName === 'lowongan' && 'text-secondary font-bold'}>Cari Lowongan</p>
							{pathName === 'lowongan' && <div className="mt-0.5 border-t-4 border-secondary w-6 mx-auto"></div>}
						</div>
					</Link>
					<Link
						to={{
							pathname: '/',
							search: createSearchParams({ goto: 'contact' }).toString(),
						}}
					>
						<div className="cursor-pointer">
							<p>Hubungi Kami</p>
							<div></div>
						</div>
					</Link>
					{/* <div>
					<p>Blog</p>
					<div></div>
				</div> */}
				</div>
				<div className="hidden lg:flex col-span-5 gap-4 justify-self-end items-center">
					{user.isLoggedIn ? (
						<>
							{/* <div>
								<img src="/images/lamaran-saya/notification-blue.svg" alt="" className="w-6" />
							</div> */}
							<Dropdown overlay={() => menu(onLogout)} onVisibleChange={handleVisibleChange} visible={nav}>
								<div className="flex items-center gap-4 px-4 py-2 cursor-pointer rounded-full hover:bg-secondary hover:bg-opacity-20">
									<div className="w-12 border-4 rounded-full border-gray-400 overflow-hidden">
										<img src={user.data.profile_picture ?? '/images/profile/bxs_user-circle.svg'} alt="" />
									</div>
									<div className="font-bold text-gray-custom">{user.data.name.split(' ')[0]}</div>
									<div>
										<img src="/images/lamaran-saya/arrow-down.svg" alt="" className="w-4" />
									</div>
								</div>
							</Dropdown>
						</>
					) : (
						<>
							<Link to="/login">
								<p className="font-bold cursor-pointer">Login</p>
							</Link>
							<Link to="/register">
								<div className="bg-secondary text-white py-4 px-5 rounded-xl font-bold cursor-pointer">Daftar</div>
							</Link>
							<a
								href="https://perusahaan.maisyah.id/login"
								className="flex bg-primary px-3 py-4 items-center gap-2 font-bold rounded-xl"
							>
								<p className="text-white ">Masuk sebagai Perusahaan</p>
							</a>
						</>
					)}
				</div>
				<div className="flex lg:hidden col-span-1"></div>
				<div className="flex lg:hidden col-span-1" onClick={() => setMobileNav(true)}>
					<img src="/images/landing/menu.svg" className="w-6" alt="" />
				</div>
				<Drawer
					title=""
					onClose={() => setMobileNav(false)}
					visible={mobileNav}
					width={'80%'}
					closeIcon={false}
					bodyStyle={{ padding: '0px' }}
				>
					<div className="w-11/12 mx-auto flex justify-between items-center my-6">
						<Link to={'/'}>
							<div className="w-32">
								<img src="/images/maisyah.svg" alt="" />
							</div>
						</Link>
						<div className="" onClick={() => setMobileNav(false)}>
							<img src="/images/landing/close.svg" className="w-6" alt="" />
						</div>
					</div>
					<div className="flex flex-col">
						<Link to={'/'}>
							<div
								className={`pl-6 py-4 text-lg bg-opacity-20 border-t-2 border-b-2 ${
									isEmpty(pathName) && 'bg-secondary text-secondary'
								}`}
							>
								Beranda
							</div>
						</Link>
						<Link to={{ pathname: '/', query: { goto: 'layanan' } }}>
							<div className={`pl-6 py-4 text-lg text-gray-custom border-b-2 bg-opacity-20 `}>Layanan</div>
						</Link>
						<Link to={'/lowongan'}>
							<div
								className={`pl-6 py-4 text-lg text-gray-custom border-b-2 bg-opacity-20 ${
									pathName === 'lowongan' && 'bg-secondary text-secondary'
								}`}
							>
								Cari Lowongan
							</div>
						</Link>
						<Link to={{ pathname: '/', query: { goto: 'contact' } }}>
							<div className={`pl-6 py-4 text-lg text-gray-custom border-b-2 bg-opacity-20`}>Hubungi Kami</div>
						</Link>
						{/* <div className={`pl-6 py-4 text-lg text-gray-custom border-b-2 bg-opacity-20`}>Blog</div> */}
						{user.isLoggedIn ? (
							<>
								<Link to={'/profile'}>
									<div
										className={`pl-6 py-4 text-lg text-gray-custom border-b-2 bg-opacity-20 ${
											pathName === 'profile' && 'bg-secondary text-secondary'
										}`}
									>
										Profile
									</div>
								</Link>
								<Link to={'/lowongan/status-lamaran'}>
									<div className={`pl-6 py-4 text-lg text-gray-custom border-b-2 bg-opacity-20`}>Status Lamaran</div>
								</Link>
								<Link to={'/lowongan/bookmark'}>
									<div
										className={`pl-6 py-4 text-lg text-gray-custom border-b-2 bg-opacity-20 ${
											pathName === 'lowongan' && 'bg-secondary text-secondary'
										}`}
									>
										Bookmark
									</div>
								</Link>
								<div onClick={onLogout} className={`pl-6 py-4 text-lg text-red-custom font-bold border-b-2 `}>
									Logout
								</div>
								<div className="flex flex-col items-center justify-center px-4 gap-2 mt-10">
									<div>
										<img
											src={user.data.profile_picture ?? '/images/profile/bxs_user-circle.svg'}
											className="w-20 rounded-full"
											alt=""
										/>
									</div>
									<div className="font-bold text-lg mt-8">{user.data.name}</div>
								</div>
							</>
						) : (
							<>
								<Link to={'/login'}>
									<div
										className={`pl-6 py-4 text-lg text-gray-custom font-bold border-b-2 bg-opacity-20 ${
											pathName === 'login' && 'bg-secondary text-secondary'
										}`}
									>
										Login
									</div>
								</Link>
								<Link to={'/register'}>
									<div
										className={`pl-6 py-4 text-lg text-secondary font-bold border-b-2 bg-opacity-20 ${
											pathName === 'register' && 'bg-secondary text-secondary'
										}`}
									>
										Daftar
									</div>
								</Link>
								<a href="https://perusahaan.maisyah.id/login" className="">
									<div className="pl-6 py-4 text-lg text-primary font-bold border-b-2 bg-opacity-20">
										Daftar Sebagai Perusahaan
									</div>
								</a>
							</>
						)}
					</div>
				</Drawer>
			</header>
		</div>
	);
}

export default Navbar;
