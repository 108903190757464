import { LoadingOutlined } from "@ant-design/icons";
import { Checkbox, DatePicker, Form, Select, Spin, Timeline } from "antd";
import { FieldArray, Formik } from "formik";
import { isEmpty, isNull } from "lodash";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import { setDone, setLoading } from "../../../../store/loading";
import axiosConfig from "../../../../utils/axiosConfig";
import { API_ENDPOINT } from "../../../../utils/config";
import { getErrorValue } from "../../../../utils/getErrors";
import FormItem from "../../../reusable/FormItem";
import Input from "../../../reusable/Input";
import { Toast } from "../../../reusable/Toast";

const initialState = {
  pekerjaans: [],
  pendidikans: [],
  deskripsi: "",
};

const { Option } = Select;

const validationSchema = Yup.object().shape({});
const loadingIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const tagRender = (props) => {
  return <TagComponent label={props.label} onClose={props.onClose} />;
};

const TagComponent = ({ onClose, label }) => {
  return (
    <div className="flex items-center gap-2 py-2 lg:py-4 px-3 lg:px-5 bg-secondary bg-opacity-20 rounded-full my-3 mr-3">
      <label className="text-secondary font-bold text-xs lg:text-base">
        {label}
      </label>
      <div onClick={onClose} className="cursor-pointer">
        <img src="/images/close-circle.svg" className="w-4 lg:w-6" alt="" />
      </div>
    </div>
  );
};

function EditTentangForm({ data, fetchProfile }) {
  const [editTentang, setEditTentang] = useState(false);
  const [input, setInput] = useState(initialState);
  const [errorMessage, setErrorMessage] = useState({});
  const [loadingSkills, setLoadingSkills] = useState(false);
  const [skills, setSkills] = useState([]);
  const [userSkills, setUserSkills] = useState([]);
  const [file, setFile] = useState(null);
  const dispatch = useDispatch();
  const fileInput = useRef(null);

  const handleClickButton = () => {
    fileInput.current.click();
  };
  const inputFile = () => {
    setFile(fileInput.current.files[0]);
  };

  const fetchSkills = async () => {
    try {
      setLoadingSkills(true);
      const response = await axiosConfig.get(`${API_ENDPOINT}/pencaker/skills`);
      setSkills(response.data.data.skills);
    } catch (error) {
      console.log(error);
      Toast.fire({
        icon: "error",
        iconColor: "#EA4735",
        title: error.response?.data.info ?? "Terjadi Suatu Error",
      });
      setErrorMessage(error.response?.data?.data?.errors);
    } finally {
      setLoadingSkills(false);
    }
  };

  const handleChangeSkill = (value) => {
    setUserSkills(value);
  };

  useEffect(() => {
    if (!isEmpty(data)) {
      setInputData();
    }
    fetchSkills();
  }, [data]);

  const setInputData = () => {
    setInput({
      pekerjaans: data.profile.user_pekerjaan,
      pendidikans: data.profile.user_pendidikan,
      deskripsi: data.profile.user_meta.deskripsi,
    });
    setUserSkills(data.profile.skills.map((skill) => skill.name));
  };

  const storeTentang = async (values) => {
    let response;
    try {
      response = await axiosConfig.post(
        `${API_ENDPOINT}/pencaker/profile/edit/tentang`,
        {
          ...values,
          skills: userSkills,
        }
      );
    } catch (error) {
      setErrorMessage(error.response?.data?.data?.errors);
      throw new Error(error.response?.data?.info);
    }
    return response;
  };

  const storeResume = async () => {
    let response;
    try {
      const formData = new FormData();
      if (!isNull(file)) {
        formData.append("resume", file);
      }
      response = await axiosConfig.post(
        `${API_ENDPOINT}/pencaker/profile/resume`,
        formData
      );
    } catch (error) {
      throw new Error(error.response.data.info);
    }
    return response;
  };

  const onSubmit = async (values, { resetForm }) => {
    dispatch(setLoading());
    try {
      const [response, response1] = await Promise.all([
        storeTentang(values),
        storeResume(),
      ]);
      setEditTentang(false);
      setErrorMessage({});
      fetchProfile();
      setInputData();
      setFile(null);
      resetForm();
      Toast.fire({
        icon: "success",
        title: "Berhasil Mengupdate Profile",
        iconColor: "#17afe3",
      });
    } catch (error) {
      Toast.fire({
        icon: "error",
        iconColor: "#EA4735",
        title: error.message,
      });
    } finally {
      dispatch(setDone());
    }
  };
  const onChangeTanggal = (
    date,
    dateString,
    key,
    sub_key,
    index,
    setFieldValue
  ) => {
    setFieldValue(`${key}.${index}.${sub_key}`, dateString);
  };

  const onChangeCheckboxTglAkhir = (setFieldValue, key, index, values) => {
    if (values) {
      setFieldValue(`${key}.${index}.tgl_akhir`, null);
    } else {
      setFieldValue(`${key}.${index}.tgl_akhir`, moment().format("YYYY-MM"));
    }
  };

  const onCancel = (resetForm) => {
    setEditTentang(false);
    setFile(null);
    setInputData();
    resetForm();
  };

  return (
    <Formik
      initialValues={input}
      enableReinitialize
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({
        isSubmitting,
        handleSubmit,
        handleBlur,
        handleChange,
        errors,
        touched,
        values,
        setFieldValue,
        setFieldTouched,
        isValid,
        dirty,
        resetForm,
      }) => (
        <Form
          id="create-loker-modal"
          wrapperCol={{
            span: 24,
          }}
          onFinish={handleSubmit}
          labelAlign="left"
          className="ml-4"
          layout={"vertical"}
        >
          <div className="flex justify-between items-center mb-10">
            <div>
              <h1 className="text-3xl font-bold my-3">Tentang Saya</h1>
            </div>
            {editTentang ? (
              <div className="flex gap-4">
                <button
                  className={`flex gap-4 items-center justify-center font-bold py-4 px-4 rounded-xl text-secondary
												`}
                  onClick={() => onCancel(resetForm)}
                >
                  Cancel
                </button>
                <button
                  className={`flex gap-4 items-center justify-center border py-4 px-4 rounded-xl ${
                    isSubmitting
                      ? "border-gray-custom bg-gray-custom border-opacity-20 bg-opacity-20 cursor-not-allowed"
                      : "bg-secondary border-secondary cursor-pointer"
                  } `}
                  type="submit"
                  disabled={isSubmitting}
                >
                  <img
                    src={`/images/profile/save-white.svg`}
                    alt=""
                    className="w-4"
                  />

                  <p className="font-bold text-lg text-white">
                    Simpan Perubahan
                  </p>
                  {isSubmitting && <Spin indicator={loadingIcon} />}
                </button>
              </div>
            ) : (
              <button
                className={`flex gap-4 items-center justify-center border px-8 border-secondary py-4 rounded-xl cursor-pointer `}
                onClick={() => setEditTentang(true)}
              >
                <img
                  src={`/images/profile/pencil-company-profile.svg`}
                  alt=""
                  className="w-8"
                />

                <p className="font-bold text-lg text-secondary">
                  Edit Tentang Saya
                </p>
              </button>
            )}
          </div>
          {editTentang ? (
            <div className="grid lg:grid-cols-12">
              <div className="lg:col-span-7">
                <FormItem
                  colon={false}
                  label={"Deskripsi"}
                  error={getErrorValue()}
                  touched={false}
                >
                  <Input
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.deskripsi}
                    name={`deskripsi`}
                    placeholder="Masukkan Deskripsi"
                  />
                </FormItem>
                <div className="grid lg:grid-cols-8 mt-8">
                  <div className="lg:col-span-2">
                    <p className="font-bold text-xl text-gray-custom mt-2 mb-4 lg:mb-0">
                      Pengalaman
                    </p>
                  </div>
                  <div className="lg:col-span-6">
                    <FieldArray name="pekerjaans">
                      {({ remove, push }) => (
                        <>
                          <div className="flex flex-col gap-4">
                            {values.pekerjaans.map((pekerjaan, index) => (
                              <div
                                key={index}
                                className="border-2 p-5 rounded-xl"
                              >
                                <FormItem
                                  label={"Perusahaan"}
                                  error={getErrorValue()}
                                  touched={false}
                                >
                                  <Input
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={pekerjaan.perusahaan}
                                    name={`[pekerjaans.${index}.perusahaan]`}
                                    placeholder="Masukkan Nama Perusahaan"
                                  />
                                </FormItem>
                                <FormItem
                                  label={"Posisi"}
                                  error={getErrorValue()}
                                  touched={false}
                                >
                                  <Input
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={pekerjaan.posisi}
                                    name={`[pekerjaans.${index}.posisi]`}
                                    placeholder="Masukkan Posisi"
                                  />
                                </FormItem>
                                <FormItem
                                  label={"Bio"}
                                  error={getErrorValue()}
                                  touched={false}
                                >
                                  <Input
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={pekerjaan.bio}
                                    name={`[pekerjaans.${index}.bio]`}
                                    placeholder="Masukkan Bio"
                                    type={"textarea"}
                                  />
                                </FormItem>
                                <FormItem
                                  label={"Periode"}
                                  // Disini untuk mengecek apakah job_responsibilities sudah berbentuk array atau tidak
                                  error={getErrorValue()}
                                  touched={false}
                                >
                                  <div className="flex flex-col lg:flex-row items-center justify-between w-full">
                                    <FormItem
                                      error={getErrorValue()}
                                      touched={false}
                                      className="mb-0"
                                      style={{
                                        width:
                                          window.screen.width < 1200
                                            ? "100%"
                                            : "40%",
                                      }}
                                    >
                                      <DatePicker
                                        onChange={(date, dateString) =>
                                          onChangeTanggal(
                                            date,
                                            dateString,
                                            "pekerjaans",
                                            "tgl_mulai",
                                            index,
                                            setFieldValue
                                          )
                                        }
                                        value={moment(
                                          pekerjaan.tgl_mulai,
                                          "YYYY-MM"
                                        )}
                                        picker="month"
                                        size="large"
                                        className="w-full"
                                      />
                                    </FormItem>
                                    <p className="text-gray-bright text-lg">
                                      sampai
                                    </p>
                                    {pekerjaan.tgl_akhir ? (
                                      <FormItem
                                        error={getErrorValue(
                                          errors.tgl_akhir,
                                          errorMessage?.tgl_akhir
                                        )}
                                        touched={touched.tgl_akhir}
                                        style={{
                                          width:
                                            window.screen.width < 1200
                                              ? "100%"
                                              : "40%",
                                        }}
                                        className="mb-0"
                                      >
                                        <DatePicker
                                          onChange={(date, dateString) =>
                                            onChangeTanggal(
                                              date,
                                              dateString,
                                              "pekerjaans",
                                              "tgl_akhir",
                                              index,
                                              setFieldValue
                                            )
                                          }
                                          value={moment(
                                            pekerjaan.tgl_akhir,
                                            "YYYY-MM"
                                          )}
                                          picker="month"
                                          size="large"
                                          className="w-full"
                                        />
                                      </FormItem>
                                    ) : (
                                      <div
                                        className="flex items-center justify-center text-xl font-bold"
                                        style={{
                                          width:
                                            window.screen.width < 1200
                                              ? "100%"
                                              : "40%",
                                        }}
                                      >
                                        Sekarang
                                      </div>
                                    )}
                                  </div>
                                </FormItem>
                                <FormItem
                                  label={""}
                                  wrapperCol={{
                                    span: window.screen.width < 1200 ? 24 : 20,
                                    offset: window.screen.width < 1200 ? 0 : 6,
                                  }}
                                  // Disini untuk mengecek apakah job_responsibilities sudah berbentuk array atau tidak
                                  error={getErrorValue()}
                                  touched={false}
                                >
                                  <div className="flex gap-2 -my-6">
                                    <Checkbox
                                      checked={
                                        pekerjaan.tgl_akhir ? false : true
                                      }
                                      onChange={() =>
                                        onChangeCheckboxTglAkhir(
                                          setFieldValue,
                                          "pekerjaans",
                                          index,
                                          pekerjaan.tgl_akhir
                                        )
                                      }
                                    />
                                    <div className="text-gray-500">
                                      Saya masih bekerja di perusahaan ini
                                    </div>
                                  </div>
                                </FormItem>
                                <FormItem wrapperCol={{ span: 24 }}>
                                  <div
                                    className="w-full flex gap-4 items-center justify-center py-3 px-4 rounded-lg bg-red-custom bg-opacity-20"
                                    onClick={() => remove(index)}
                                  >
                                    <img
                                      src="/images/profile/bi_trash-fill.svg"
                                      alt=""
                                      className="w-6"
                                    />
                                    <p className="text-lg text-red-custom">
                                      Hapus Pengalaman Ini
                                    </p>
                                  </div>
                                </FormItem>
                              </div>
                            ))}
                          </div>
                          <button
                            type="button"
                            className="w-full flex gap-4 items-center border-2 border-dashed border-primary py-3 px-4 rounded-lg bg-primary bg-opacity-10 mt-6"
                            onClick={() =>
                              push({
                                perusahaan: "",
                                posisi: "",
                                tgl_mulai: moment().format("YYYY-MM"),
                                tgl_akhir: moment().format("YYYY-MM"),
                                bio: "",
                              })
                            }
                          >
                            <img
                              src="/images/profile/akar-icons_circle-plus.svg"
                              alt=""
                              className="w-8"
                            />
                            <p className="text-lg text-primary">
                              Tambah Pengalaman
                            </p>
                          </button>
                        </>
                      )}
                    </FieldArray>
                  </div>
                </div>
                <div className="grid lg:grid-cols-8 mt-8">
                  <div className="lg:col-span-2">
                    <p className="font-bold text-xl text-gray-custom mt-2 mb-4 lg:mb-0">
                      Pendidikan
                    </p>
                  </div>
                  <div className="lg:col-span-6">
                    <FieldArray name="pendidikans">
                      {({ remove, push }) => (
                        <>
                          <div className="flex flex-col gap-4">
                            {values.pendidikans.map((pendidikan, index) => (
                              <div
                                key={index}
                                className="border-2 p-5 rounded-xl"
                              >
                                <FormItem
                                  label={"Institusi"}
                                  // Disini untuk mengecek apakah job_responsibilities sudah berbentuk array atau tidak
                                  error={getErrorValue()}
                                  touched={false}
                                >
                                  <Input
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={pendidikan.institusi}
                                    name={`[pendidikans.${index}.institusi]`}
                                    placeholder="Masukkan Nama Institusi"
                                  />
                                </FormItem>
                                <FormItem
                                  label={"Gelar"}
                                  // Disini untuk mengecek apakah job_responsibilities sudah berbentuk array atau tidak
                                  error={getErrorValue()}
                                  touched={false}
                                >
                                  <Input
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={pendidikan.gelar}
                                    name={`[pendidikans.${index}.gelar]`}
                                    placeholder="Masukkan Gelar"
                                  />
                                </FormItem>
                                <FormItem
                                  label={"Bidang Studi"}
                                  // Disini untuk mengecek apakah job_responsibilities sudah berbentuk array atau tidak
                                  error={getErrorValue()}
                                  touched={false}
                                >
                                  <Input
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={pendidikan.bidang_studi}
                                    name={`[pendidikans.${index}.bidang_studi]`}
                                    placeholder="Masukkan Bidang Studi"
                                  />
                                </FormItem>
                                <FormItem
                                  label={"Bio"}
                                  error={getErrorValue()}
                                  touched={false}
                                >
                                  <Input
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={pendidikan.bio}
                                    name={`[pendidikans.${index}.bio]`}
                                    placeholder="Masukkan Bio"
                                    type={"textarea"}
                                  />
                                </FormItem>
                                <FormItem
                                  label={"Periode"}
                                  // Disini untuk mengecek apakah job_responsibilities sudah berbentuk array atau tidak
                                  error={getErrorValue()}
                                  touched={false}
                                >
                                  <div className="flex flex-col lg:flex-row items-center justify-between w-full">
                                    <FormItem
                                      error={getErrorValue()}
                                      touched={false}
                                      className="mb-0"
                                      style={{
                                        width:
                                          window.screen.width < 1200
                                            ? "100%"
                                            : "40%",
                                      }}
                                    >
                                      <DatePicker
                                        onChange={(date, dateString) =>
                                          onChangeTanggal(
                                            date,
                                            dateString,
                                            "pendidikans",
                                            "tgl_mulai",
                                            index,
                                            setFieldValue
                                          )
                                        }
                                        value={moment(
                                          pendidikan.tgl_mulai,
                                          "YYYY-MM"
                                        )}
                                        picker="month"
                                        size="large"
                                        className="w-full"
                                      />
                                    </FormItem>
                                    <p className="text-gray-bright text-lg">
                                      sampai
                                    </p>
                                    {pendidikan.tgl_akhir ? (
                                      <FormItem
                                        error={getErrorValue(
                                          errors.tgl_akhir,
                                          errorMessage?.tgl_akhir
                                        )}
                                        touched={touched.tgl_akhir}
                                        style={{
                                          width:
                                            window.screen.width < 1200
                                              ? "100%"
                                              : "40%",
                                        }}
                                        className="mb-0"
                                      >
                                        <DatePicker
                                          onChange={(date, dateString) =>
                                            onChangeTanggal(
                                              date,
                                              dateString,
                                              "pendidikans",
                                              "tgl_akhir",
                                              index,
                                              setFieldValue
                                            )
                                          }
                                          value={moment(
                                            pendidikan.tgl_akhir,
                                            "YYYY-MM"
                                          )}
                                          picker="month"
                                          size="large"
                                          className="w-full"
                                        />
                                      </FormItem>
                                    ) : (
                                      <div
                                        className="flex items-center justify-center text-xl font-bold"
                                        style={{
                                          width:
                                            window.screen.width < 1200
                                              ? "100%"
                                              : "40%",
                                        }}
                                      >
                                        Sekarang
                                      </div>
                                    )}
                                  </div>
                                </FormItem>
                                <FormItem
                                  label={""}
                                  wrapperCol={{
                                    span: window.screen.width < 1200 ? 24 : 20,
                                    offset: window.screen.width < 1200 ? 0 : 6,
                                  }}
                                  // Disini untuk mengecek apakah job_responsibilities sudah berbentuk array atau tidak
                                  error={getErrorValue()}
                                  touched={false}
                                >
                                  <div className="flex gap-2 -my-6">
                                    <Checkbox
                                      checked={
                                        pendidikan.tgl_akhir ? false : true
                                      }
                                      onChange={() =>
                                        onChangeCheckboxTglAkhir(
                                          setFieldValue,
                                          "pendidikans",
                                          index,
                                          pendidikan.tgl_akhir
                                        )
                                      }
                                    />
                                    <div className="text-gray-500">
                                      Saya masih bersekolah di institusi ini
                                    </div>
                                  </div>
                                </FormItem>
                                <FormItem wrapperCol={{ span: 24 }}>
                                  <div
                                    onClick={() => remove(index)}
                                    className="w-full flex gap-4 items-center justify-center py-3 px-4 rounded-lg bg-red-custom bg-opacity-20"
                                  >
                                    <img
                                      src="/images/profile/bi_trash-fill.svg"
                                      alt=""
                                      className="w-6"
                                    />
                                    <p className="text-lg text-red-custom">
                                      Hapus Pendidikan Ini
                                    </p>
                                  </div>
                                </FormItem>
                              </div>
                            ))}
                          </div>
                          <button
                            onClick={() =>
                              push({
                                institusi: "",
                                gelar: "",
                                bidang_studi: "",
                                tgl_mulai: moment().format("YYYY-MM"),
                                tgl_akhir: moment().format("YYYY-MM"),
                                bio: "",
                              })
                            }
                            type="button"
                            className="w-full flex gap-4 items-center border-2 border-dashed border-primary py-3 px-4 rounded-lg bg-primary bg-opacity-10 mt-6"
                          >
                            <img
                              src="/images/profile/akar-icons_circle-plus.svg"
                              alt=""
                              className="w-8"
                            />
                            <p className="text-lg text-primary">
                              Tambah Pendidikan
                            </p>
                          </button>
                        </>
                      )}
                    </FieldArray>
                  </div>
                </div>
              </div>
              <div className="hidden lg:flex col-span-1 justify-center">
                <div className="border h-full"></div>
              </div>
              <div className="lg:col-span-4">
                <div className="mb-12">
                  <h2 className="font-bold text-3xl text-gray-custom mb-4">
                    Resume
                  </h2>
                  <button
                    className="w-full my-2 py-6 px-8 flex justify-between items-center border-2 border-dashed border-primary bg-primary bg-opacity-10 rounded-xl cursor-pointer"
                    onClick={() => handleClickButton()}
                    type="button"
                  >
                    <div className="text-lg">
                      {file == null
                        ? "Upload Resume(.pdf/.doc/.docx)"
                        : "Sudah Terupload"}
                    </div>
                    <img src="/images/file-people.svg" className="w-6" alt="" />
                  </button>
                  <div className="hidden">
                    <input
                      type="file"
                      ref={fileInput}
                      accept="application/pdf"
                      onChange={() => inputFile()}
                    />
                  </div>
                </div>
                <div>
                  <h2 className="font-bold text-3xl text-gray-custom mb-4">
                    Skill
                  </h2>
                  <Select
                    mode="tags"
                    style={{
                      width: "100%",
                    }}
                    placeholder="Tags Mode"
                    onChange={handleChangeSkill}
                    value={userSkills}
                    size="large"
                    tagRender={tagRender}
                    showArrow
                  >
                    {skills.map((skill) => (
                      <Option key={skill.id}>{skill.name}</Option>
                    ))}
                  </Select>
                </div>
              </div>
            </div>
          ) : (
            <div className="grid lg:grid-cols-12">
              <div className="lg:col-span-7">
                <div className="grid lg:grid-cols-8">
                  <div className="lg:col-span-2">
                    <p className="font-bold text-xl text-gray-custom mt-2">
                      Deskripsi
                    </p>
                  </div>
                  <div className="lg:col-span-6 border-2 rounded-lg py-2 px-4 bg-gray-100 cursor-not-allowed">
                    ini adalah isi dari deskripsi
                  </div>
                </div>
                <div className="grid lg:grid-cols-8 mt-8">
                  <div className="lg:col-span-2">
                    <p className="font-bold text-xl text-gray-custom mt-2 mb-4 lg:mb-0">
                      Pengalaman
                    </p>
                  </div>
                  <div className="lg:col-span-6">
                    <Timeline>
                      {data.profile?.user_pekerjaan.map((pekerjaan, index) => (
                        <Timeline.Item
                          key={index}
                          color={pekerjaan.tgl_akhir ? "gray" : "blue"}
                        >
                          <div className="flex flex-col gap-1">
                            <h1 className="text-xl font-bold text-gray-custom">
                              {pekerjaan.posisi}
                            </h1>
                            <h2 className="text-lg text-gray-custom">
                              {pekerjaan.perusahaan}
                            </h2>
                            <h2 className="text-lg text-gray-custom">
                              {moment(pekerjaan.tgl_mulai, "YYYY-MM").format(
                                "MMMM YYYY"
                              )}{" "}
                              -{" "}
                              {pekerjaan.tgl_akhir
                                ? moment(pekerjaan.tgl_akhir, "YYYY-MM").format(
                                    "MMMM YYYY"
                                  )
                                : "Sekarang"}
                            </h2>
                            <p className="text-base">{pekerjaan.bio}</p>
                          </div>
                        </Timeline.Item>
                      ))}
                    </Timeline>
                  </div>
                </div>
                <div className="grid lg:grid-cols-8 mt-8">
                  <div className="lg:col-span-2">
                    <p className="font-bold text-xl text-gray-custom mt-2 mb-4 lg:mb-0">
                      Pendidikan
                    </p>
                  </div>
                  <div className="lg:col-span-6">
                    <Timeline>
                      {data.profile?.user_pendidikan.map(
                        (pendidikan, index) => (
                          <Timeline.Item
                            key={index}
                            color={pendidikan.tgl_akhir ? "gray" : "blue"}
                          >
                            <div className="flex flex-col gap-1">
                              <h1 className="text-xl font-bold text-gray-custom">
                                {pendidikan.institusi}
                              </h1>
                              <h2 className="text-lg text-gray-custom">
                                {pendidikan.gelar}
                              </h2>
                              <h2 className="text-lg text-gray-custom">
                                {pendidikan.bidang_studi}
                              </h2>
                              <h2 className="text-lg text-gray-custom">
                                {moment(pendidikan.tgl_mulai, "YYYY-MM").format(
                                  "MMMM YYYY"
                                )}{" "}
                                -{" "}
                                {pendidikan.tgl_akhir
                                  ? moment(
                                      pendidikan.tgl_akhir,
                                      "YYYY-MM"
                                    ).format("MMMM YYYY")
                                  : "Sekarang"}
                              </h2>
                              <p className="text-base">{pendidikan.bio}</p>
                            </div>
                          </Timeline.Item>
                        )
                      )}
                    </Timeline>
                  </div>
                </div>
              </div>
              <div className="hidden col-span-1 lg:flex justify-center">
                <div className="border h-full"></div>
              </div>
              <div className="lg:col-span-4">
                <div className="mb-12">
                  <h2 className="font-bold text-3xl text-gray-custom mb-4">
                    Resume
                  </h2>
                  {data.profile?.user_meta.resume ? (
                    <div
                      className="text-center cursor-pointer border-2 border-primary py-3 px-4 rounded-lg bg-primary bg-opacity-10 text-primary"
                      onClick={() => window.open(data.profile.user_meta.resume)}
                    >
                      Klik disini untuk melihat resume
                    </div>
                  ) : (
                    <div className="text-center text-gray-custom bg-gray-custom bg-opacity-10 border-2 border-gray-custom py-3 px-4 rounded-lg">
                      Resume Belum diupload
                    </div>
                  )}
                </div>
                <div>
                  <h2 className="font-bold text-3xl text-gray-custom mb-4">
                    Skill
                  </h2>
                  <div className="grid grid-cols-2 gap-4">
                    {data.profile?.skills.map((skill, index) => (
                      <div
                        key={index}
                        className="bg-primary bg-opacity-10 px-5 py-3 rounded-lg text-primary text-xl font-bold text-center"
                      >
                        {skill.name}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          )}
        </Form>
      )}
    </Formik>
  );
}

export default EditTentangForm;
