import { Drawer, Image } from 'antd';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { deleteUser } from '../../../../store/user';
import axiosConfig from '../../../../utils/axiosConfig';
import { API_ENDPOINT } from '../../../../utils/config';

function MobileNav() {
	const [mobileNav, setMobileNav] = useState(false);
	const user = useSelector(state => state.user);

	const dispatch = useDispatch();

	const navigate = useNavigate();

	const pathName = window.location.pathname.split('/')[1];

	const onLogout = async () => {
		try {
			const response = await axiosConfig.post(`${API_ENDPOINT}/pencaker/logout`, {});
		} catch (e) {
			console.log(e);
		}

		dispatch(deleteUser());
		navigate('/login');
	};

	return (
		<nav className="w-11/12 mx-auto grid lg:hidden grid-cols-12 items-center py-8 text-lg">
			<div className="col-span-10 lg:col-span-2 ">
				<Link to={'/'}>
					<div className="w-40 cursor-pointer">
						<Image layout="responsive" width={180} height={55} src="/images/maisyah.svg" alt="" />
					</div>
				</Link>
			</div>
			<div className="flex lg:hidden col-span-1"></div>
			<div className="flex lg:hidden col-span-1" onClick={() => setMobileNav(true)}>
				<img src="/images/landing/menu.svg" className="w-6" alt="" />
			</div>
			<Drawer
				title=""
				onClose={() => setMobileNav(false)}
				visible={mobileNav}
				width={'80%'}
				closeIcon={false}
				bodyStyle={{ padding: '0px' }}
			>
				<div className="w-11/12 mx-auto flex justify-between items-center my-6">
					<Link to={'/'}>
						<div className="w-32">
							<img src="/images/maisyah.svg" alt="" />
						</div>
					</Link>
					<div className="" onClick={() => setMobileNav(false)}>
						<img src="/images/landing/close.svg" className="w-6" alt="" />
					</div>
				</div>
				<div className="flex flex-col">
					<Link to={'/'}>
						<div className={`pl-6 py-4 text-lg bg-opacity-20 border-t-2 border-b-2`}>Beranda</div>
					</Link>
					<Link to={{ pathname: '/', query: { goto: 'layanan' } }}>
						<div className={`pl-6 py-4 text-lg text-gray-custom border-b-2 bg-opacity-20 `}>Layanan</div>
					</Link>

					<Link to={'/lowongan'}>
						<div className={`pl-6 py-4 text-lg text-gray-custom border-b-2 bg-opacity-20 bg-secondary text-secondary`}>
							Cari Lowongan
						</div>
					</Link>
					<Link to={{ pathname: '/', query: { goto: 'contact' } }}>
						<div className={`pl-6 py-4 text-lg text-gray-custom border-b-2 bg-opacity-20`}>Hubungi Kami</div>
					</Link>
					{/* <div className={`pl-6 py-4 text-lg text-gray-custom border-b-2 bg-opacity-20`}>Blog</div> */}
					{user.isLoggedIn ? (
						<>
							<Link to={'/profile'}>
								<div
									className={`pl-6 py-4 text-lg text-gray-custom border-b-2 bg-opacity-20 ${
										pathName === 'profile' && 'bg-secondary text-secondary'
									}`}
								>
									Profile
								</div>
							</Link>
							<Link to={'/lowongan/status-lamaran'}>
								<div className={`pl-6 py-4 text-lg text-gray-custom border-b-2 bg-opacity-20`}>Status Lamaran</div>
							</Link>
							<Link to={'/lowongan/bookmark'}>
								<div className={`pl-6 py-4 text-lg text-gray-custom border-b-2 bg-opacity-20`}>Bookmark</div>
							</Link>
							<div onClick={onLogout} className={`pl-6 py-4 text-lg text-red-custom font-bold border-b-2 `}>
								Logout
							</div>
							<div className="flex flex-col items-center justify-center px-4 gap-2 mt-10">
								<div>
									<img src="/images/profile/bxs_user-circle.svg" className="w-32" alt="" />
								</div>
								<div className="font-bold text-2xl">{user.data.name}</div>
							</div>
						</>
					) : (
						<>
							<Link to={'/login'}>
								<div className={`pl-6 py-4 text-lg text-gray-custom font-bold border-b-2 bg-opacity-20 `}>Login</div>
							</Link>
							<Link to={'/register'}>
								<div className={`pl-6 py-4 text-lg text-secondary font-bold border-b-2 bg-opacity-20 `}>Daftar</div>
							</Link>
							<a
								to="https://perusahaan.maisyah.id/login"
								className="flex bg-primary px-3 py-4 items-center gap-2 font-bold text-white rounded-xl"
							>
								<div className="pl-6 py-4 text-lg text-primary font-bold border-b-2 bg-opacity-20">
									Daftar Sebagai Perusahaan
								</div>
							</a>
						</>
					)}
				</div>
			</Drawer>
		</nav>
	);
}

export default MobileNav;
