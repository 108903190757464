import { Link } from 'react-router-dom';

function Navbar({ href }) {
	//  HEADER
	return (
		<nav className="hidden lg:flex items-center justify-between flex-wrap bg-teal py-6 px-10">
			<div className="w-40 cursor-pointer">
				<img layout="responsive" width={180} height={55} src="/images/maisyah.svg" alt="" />
			</div>
			{/* LEWATI LANGKAH */}
			<Link to={href}>
				<div className="rounded-xl border text-center justify-center items-center flex p-5 leading-none text-gray-400 border-gray-400 border-bold cursor-pointer">
					<p>Lewati langkah ini</p>
					<img src="/images/arrow-gray.svg" alt="" className="w-4 ml-2" />
				</div>
			</Link>
		</nav>
	);
}

export default Navbar;
