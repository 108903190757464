import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import isAuthenticated from '../../utils/isAuthenticated';

function UnauthenticatedRoutes({ children }) {
	if (isAuthenticated()) {
		return <Navigate to="/lowongan" replace />;
	}

	return children ?? <Outlet />;
}

export default UnauthenticatedRoutes;
