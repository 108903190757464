import { Dropdown, Menu } from 'antd';

import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { deleteUser } from '../../../../store/user';
import axiosConfig from '../../../../utils/axiosConfig';
import { API_ENDPOINT } from '../../../../utils/config';

const menu = onLogout => (
	<Menu className="p-4">
		<Menu.Item className=" my-1 hover:bg-secondary hover:bg-opacity-10">
			<Link to={'/profile'}>
				<div className="flex items-center gap-4 py-2 rounded-xl">
					<div>
						<img src="/images/lowongan/profile-gray.svg" className="w-6" alt="" />
					</div>
					<div>Profil</div>
				</div>
			</Link>
		</Menu.Item>
		<Menu.Item className="my-1 hover:bg-secondary hover:bg-opacity-10">
			<Link to={'/lowongan/status-lamaran'}>
				<div className="flex items-center py-2 rounded-xl">
					<div className="flex items-center gap-4">
						<div>
							<img src="/images/lowongan/file-gray.svg" className="w-6" alt="" />
						</div>
						<div>Status Lamaran</div>
					</div>
					<div className="text-red-custom font-bold flex ml-4">3</div>
				</div>
			</Link>
		</Menu.Item>
		<Menu.Item className="my-1 hover:bg-secondary hover:bg-opacity-10">
			<Link to={'/lowongan/bookmark'}>
				<div className="flex items-center py-2 rounded-xl">
					<div className="flex items-center gap-4">
						<div>
							<img src="/images/lowongan/bookmark-gray.svg" className="w-6" alt="" />
						</div>
						<div>Bookmarks</div>
					</div>
					<div className="text-red-custom font-bold flex ml-4">3</div>
				</div>
			</Link>
		</Menu.Item>
		<Menu.Item className="my-1 hover:bg-secondary hover:bg-opacity-10">
			<div className="flex items-center gap-4 py-2 rounded-xl" onClick={onLogout}>
				<div>
					<img src="/images/lowongan/logout-red.svg" className="w-5 ml-1" alt="" />
				</div>
				<div className="text-red-custom font-bold ml-1">Logout</div>
			</div>
		</Menu.Item>
	</Menu>
);

function Navbar() {
	const [nav, setNav] = useState(false);

	const user = useSelector(state => state.user);
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const handleVisibleChange = flag => {
		setNav(flag);
	};
	const onLogout = async () => {
		try {
			const response = await axiosConfig.post(`${API_ENDPOINT}/pencaker/logout`, {});
		} catch (e) {
			console.log(e);
		}

		dispatch(deleteUser());
		navigate('/login');
	};

	return (
		<nav className="w-11/12 mx-auto hidden lg:grid grid-cols-12 items-center gap-6">
			<Link to="/" className="col-span-2">
				<img src="/images/maisyah-white.png" className="w-36" alt="" />
			</Link>
			<div className="col-span-7 flex items-center gap-8 text-opacity-80">
				<Link to="/">
					<div className="cursor-pointer">
						<p className="text-white">Beranda</p>
						<div className=""></div>
					</div>
				</Link>
				<Link to={{ pathname: '/', query: { goto: 'layanan' } }}>
					<div className="cursor-pointer">
						<p className="text-white">Layanan</p>
						<div></div>
					</div>
				</Link>
				<div className="cursor-pointer">
					<p className="text-white font-bold">Cari Lowongan</p>
					<div className="mt-0.5 border-t-4 border-white w-6 mx-auto"></div>
				</div>
				<Link to={{ pathname: '/', query: { goto: 'contact' } }}>
					<div className="cursor-pointer">
						<p className="text-white">Hubungi Kami</p>
						<div></div>
					</div>
				</Link>
				{/* <div className="cursor-pointer">
					<p>Blog</p>
					<div></div>
				</div> */}
			</div>
			{user.isLoggedIn ? (
				<div className="col-span-3 justify-center flex gap-8 items-center">
					<div>{/* <img src="/images/lowongan/notifications.png" alt="" className="w-6" /> */}</div>
					<Dropdown overlay={() => menu(onLogout)} onVisibleChange={handleVisibleChange} visible={nav}>
						<div className="flex items-center gap-4 px-4 py-2 cursor-pointer rounded-full hover:bg-sky-400">
							<div className="w-12 border-4 rounded-full border-white overflow-hidden">
								<img src={user.data.profile_picture ?? '/images/profile/bxs_user-circle.svg'} alt="" />
							</div>
							<div className="font-bold text-white">{user.data.name.split(' ')[0]}</div>
							<div>
								<img src="/images/lowongan/arrow-bottom.png" alt="" className="w-4" />
							</div>
						</div>
					</Dropdown>
				</div>
			) : (
				<div className="col-span-3 justify-center flex gap-4 items-center">
					<Link to="/login">
						<div className="font-bold cursor-pointer py-4 px-6 rounded-xl border-2 border-primary bg-white text-primary ">
							Login
						</div>
					</Link>
					<Link to="/register">
						<div className="bg-secondary border-2 border-secondary text-white py-4 px-6 rounded-xl font-bold cursor-pointer">
							Daftar
						</div>
					</Link>
				</div>
			)}
		</nav>
	);
}

export default Navbar;
