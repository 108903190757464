import { Form } from 'antd';
import { Formik } from 'formik';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { createSearchParams, Link, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { setDone, setLoading } from '../../../store/loading';
import { setUser } from '../../../store/user';
import axiosConfig from '../../../utils/axiosConfig';
import { API_ENDPOINT } from '../../../utils/config';
import { getErrorValue } from '../../../utils/getErrors';
import FormItem from '../../reusable/FormItem';
import Input from '../../reusable/Input';
import SubmitButton from '../../reusable/SubmitButton';
import { Toast } from '../../reusable/Toast';

const validationSchema = Yup.object().shape({
	email: Yup.string().email('Email tidak valid').required('Email wajib diisi'),
	password: Yup.string().required('Password wajib diisi'),
});

function Login() {
	const initialState = {
		email: '',
		password: '',
	};

	const dispatch = useDispatch();
	const navigate = useNavigate();

	const [input, setInput] = useState(initialState);
	const [errorMessage, setErrorMessage] = useState({});

	const onSubmit = async (values, { resetForm }) => {
		try {
			dispatch(setLoading());
			const response = await axiosConfig.post(`${API_ENDPOINT}/pencaker/login`, values);
			Toast.fire({
				icon: 'success',
				title: response.data.info,
				iconColor: '#17afe3',
			});
			setErrorMessage({});
			dispatch(setUser(response.data.data));
			dispatch(setDone());
			navigate({ pathname: '/lowongan' });
		} catch (error) {
			Toast.fire({
				icon: 'error',
				iconColor: '#EA4735',
				title: error.response?.data.info ?? 'Terjadi Suatu Error',
			});
			dispatch(setDone());
			if (error.response.status === 403) {
				navigate({
					pathname: '/verification',
					search: createSearchParams({ id: error.response.data.data.user.id }).toString(),
				});
			}
			setErrorMessage(error.response?.data?.data?.errors);
		}
	};

	return (
		<div className="antialiased min-h-screen flex flex-col justify-between lg:justify-start text-gray-custom  bg-contain bg-no-repeat bg-bottom lg:bg-wave-small">
			{/* HEADER  */}
			<nav className="hidden lg:flex items-center justify-between flex-wrap bg-teal w-11/12 mx-auto py-6 ">
				<Link to="/">
					<div className="w-40 cursor-pointer">
						<img layout="responsive" width={180} height={55} src="/images/maisyah.svg" alt="" />
					</div>
				</Link>
				<div className="flex gap-6 items-center text-xl">
					<div className="">Belum Punya Akun?</div>
					<Link to="/register" className="border-2 rounded-xl border-secondary font-medium py-3 px-6">
						<p className="text-secondary">Daftar</p>
					</Link>
				</div>
			</nav>
			<nav className="flex flex-col gap-2 w-10/12 mx-auto mt-24 mb-6 lg:hidden">
				<div className="text-3xl font-bold">Masuk</div>
				<div className="text-xl">
					Belum punya akun?{' '}
					<Link to="/register">
						<span className="text-secondary">Daftar.</span>
					</Link>
				</div>
			</nav>
			<div className="flex flex-col items-center justify-center w-full ">
				<div className="bg-white rounded-lg lg:drop-shadow-xl lg:p-12 w-10/12 lg:w-144 mx-auto text-lg mb-20 lg:my-20">
					<h1 className="font-bold text-center mb-8 text-3xl hidden lg:block">Selamat Datang Kembali</h1>
					<Formik initialValues={input} enableReinitialize validationSchema={validationSchema} onSubmit={onSubmit}>
						{({
							isSubmitting,
							handleSubmit,
							handleBlur,
							handleChange,
							errors,
							touched,
							values,
							setFieldValue,
							setFieldTouched,
							isValid,
							dirty,
						}) => (
							<Form onFinish={handleSubmit} layout="vertical">
								{/* EMAIL */}
								<FormItem
									label="Email"
									error={getErrorValue(errors.email, errorMessage?.email)}
									touched={touched.email}
								>
									<Input
										onChange={handleChange}
										onBlur={handleBlur}
										value={values.email}
										name="email"
										placeholder="Masukkan email anda"
										image={'/images/email.png'}
									/>
								</FormItem>
								{/* Password */}
								<FormItem
									label="Password"
									error={getErrorValue(errors.password, errorMessage?.password)}
									touched={touched.password}
								>
									<Input
										onChange={handleChange}
										onBlur={handleBlur}
										value={values.password}
										name="password"
										placeholder="Masukkan password anda"
										image={'/images/password.png'}
										type="password"
									/>
								</FormItem>
								<div className="mb-6 flex justify-between">
									<div className="flex items-center justify-center gap-2">
										{/* <div>
											<input type="checkbox" name="remember" id="remember" />
										</div>
										<div className="text-sm mt-1">Ingatkan saya</div> */}
									</div>
									<Link to="/forgot-password" className="">
										<div className="text-secondary hover:text-orange-500 cursor-pointer">Lupa Password?</div>
									</Link>
								</div>
								<SubmitButton className="py-3 mb-4" dirty={dirty} isValid={isValid} isSubmitting={isSubmitting}>
									Login
								</SubmitButton>

								{/* <button className="w-full border text-xl border-gray-500 font-bold rounded-xl text-center flex items-center justify-center gap-4 py-3">
									<div>
										<img src="/images/google.png" alt="" className="w-6" />
									</div>
									<div>Masuk dengan Google</div>
								</button> */}
							</Form>
						)}
					</Formik>
				</div>
			</div>
			<div className="h-48 w-full bg-wave-small bg-cover bg-top lg:hidden"></div>
		</div>
	);
}

export default Login;
