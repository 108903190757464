import { Empty } from 'antd';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { createSearchParams, Link, useNavigate, useSearchParams } from 'react-router-dom';
import { setDone, setLoading } from '../../../../store/loading';
import axiosConfig from '../../../../utils/axiosConfig';
import { API_ENDPOINT } from '../../../../utils/config';
import Footer from '../../../reusable/Footer';
import Navbar from '../../../reusable/Navbar';
import { Toast } from '../../../reusable/Toast';
import JobCard from './_partials/JobCard';

function StatusLamaran() {
	const [lamaran, setLamaran] = useState([]);
	const navigate = useNavigate();
	const [searchParams, setSearchParams] = useSearchParams();

	const status = searchParams.get('status');

	const dispatch = useDispatch();

	const fetchStatusLamaran = async () => {
		try {
			dispatch(setLoading());
			const response = await axiosConfig.get(`${API_ENDPOINT}/pencaker/lamaran`, { params: { status } });
			setLamaran(response.data.data.lamaran);
		} catch (error) {
			Toast.fire({
				icon: 'error',
				iconColor: '#EA4735',
				title: error.response?.data.info ?? 'Terjadi Suatu Error',
			});
		} finally {
			dispatch(setDone());
		}
	};

	const filterBy = status => {
		if (status) {
			navigate({
				pathname: window.location.pathname,
				search: createSearchParams({ status }).toString(),
			});
		} else {
			navigate({ pathname: window.location.pathname });
		}
	};

	useEffect(() => {
		fetchStatusLamaran();
	}, [status]);

	console.log(status);

	return (
		<div>
			<Navbar />
			<div className="border-t-2">
				<div className="flex justify-center gap-4 lg:w-11/12 xl:w-10/12 mx-auto">
					<div className="hidden lg:w-1/2 lg:block px-4 border-r-2 py-4">
						<h1 className="text-3xl font-bold my-4">Status Lamaran</h1>
						<div className="flex flex-col mt-8 pb-8 border-b-2">
							<button
								onClick={() => filterBy()}
								className={`py-4 px-8 rounded-lg ${status == null && 'bg-secondary bg-opacity-20'}`}
							>
								<div
									className={`flex gap-4 items-center rounded-sm ${status == null && 'border-secondary border-r-8'} `}
								>
									<div>
										<img
											src={
												status == null
													? '/images/lamaran-saya/bx_bxs-grid-alt-active.svg'
													: '/images/lamaran-saya/bx_bxs-grid-alt.svg'
											}
											className="w-10"
											alt=""
										/>
									</div>
									<div className={`text-lg font-bold ${status == null && 'text-secondary'}`}>Semua</div>
								</div>
							</button>
							<button
								onClick={() => filterBy('sent')}
								className={`py-4 px-8 rounded-lg ${status === 'sent' && 'bg-secondary bg-opacity-20'}`}
							>
								<div
									className={`flex gap-4 items-center rounded-sm ${status === 'sent' && 'border-secondary border-r-8'}`}
								>
									<div>
										<img
											src={
												status === 'sent'
													? '/images/lamaran-saya/bi_file-earmark-check-fill-active.svg'
													: '/images/lamaran-saya/bi_file-earmark-check-fill.svg'
											}
											className="w-10"
											alt=""
										/>
									</div>
									<div className={`text-lg font-bold ${status === 'sent' && 'text-secondary'}`}>Terkirim</div>
								</div>
							</button>
							<button
								onClick={() => filterBy('review')}
								className={`py-4 px-8 rounded-lg ${status === 'review' && 'bg-secondary bg-opacity-20'}`}
							>
								<div
									className={`flex gap-4 items-center rounded-sm ${
										status === 'review' && 'border-secondary border-r-8'
									}`}
								>
									<div>
										<img
											src={
												status === 'review'
													? '/images/lamaran-saya/bi_file-earmark-check-fill-search-active.svg'
													: '/images/lamaran-saya/bi_file-earmark-check-fill-search.svg'
											}
											className="w-10"
											alt=""
										/>
									</div>
									<div className={`text-lg font-bold ${status === 'review' && 'text-secondary'}`}>Dalam Review</div>
								</div>
							</button>
							<button
								onClick={() => filterBy('failed')}
								className={`py-4 px-8 rounded-lg ${status === 'failed' && 'bg-secondary bg-opacity-20'}`}
							>
								<div
									className={`flex gap-4 items-center rounded-sm ${
										status === 'failed' && 'border-secondary border-r-8 '
									}`}
								>
									<div>
										<img
											src={
												status === 'failed'
													? '/images/lamaran-saya/bi_file-earmark-check-fill-active.svg'
													: '/images/lamaran-saya/bi_file-earmark-check-fill.svg'
											}
											className="w-10"
											alt=""
										/>
									</div>
									<div className={`text-lg font-bold ${status === 'failed' && 'text-secondary'}`}>Tidak Lolos</div>
								</div>
							</button>
						</div>
					</div>
					<div className="w-full lg:max-w-3xl ml-6 marker:border-black rounded-lg box-border">
						<div className="flex">
							<div className="w-full">
								<h1 className="hidden lg:block my-10 ml-5 text-2xl font-bold">{lamaran.length} Lamaran</h1>
								<div className="flex items-center gap-2 my-4 lg:hidden ml-0">
									<Link to={'/lowongan'}>
										<img src="/images/lowongan-detail/arrow-left.png" alt="" className="w-7" />
									</Link>
									<h1 className="font-bold text-xl">Kembali</h1>
								</div>
								<div className="flex lg:hidden border-b-2 lg:border-none my-5 gap-6">
									<div
										onClick={() => filterBy()}
										className={`flex items-center py-3 rounded-lg ${status == null && 'border-secondary border-b-8'}`}
									>
										<div className={`font-bold text-sm ${status == null ? 'text-secondary' : 'text-zinc-400'}`}>
											Semua
										</div>
									</div>
									<div
										onClick={() => filterBy('sent')}
										className={`flex items-center py-3 rounded-lg ${
											status === 'sent' && 'border-secondary border-b-8'
										}`}
									>
										<div className={`font-bold text-sm ${status === 'sent' ? 'text-secondary' : 'text-zinc-400'}`}>
											Terkirim
										</div>
									</div>
									<div
										onClick={() => filterBy('review')}
										className={`flex items-center py-3 rounded-lg ${
											status === 'review' && 'border-secondary border-b-8'
										}`}
									>
										<div className={`font-bold  text-sm ${status === 'review' ? 'text-secondary' : 'text-zinc-400'}`}>
											Dalam Review
										</div>
									</div>
									<div
										onClick={() => filterBy('failed')}
										className={`flex items-center py-3 rounded-lg ${
											status === 'failed' && 'border-secondary border-b-8'
										}`}
									>
										<div className={`font-bold text-sm ${status === 'failed' ? 'text-secondary' : 'text-zinc-400'}`}>
											Tidak Lolos
										</div>
									</div>
								</div>

								{/* BODY */}
								<div className="w-full flex flex-col my-10 gap-6">
									{lamaran.length === 0 ? <Empty /> : lamaran.map((item, index) => <JobCard data={item} key={index} />)}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<Footer />
		</div>
	);
}

export default StatusLamaran;
