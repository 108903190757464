import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import AuthenticatedRoutes from './components/HOC/AuthenticatedRoutes';
import UnauthenticatedRoutes from './components/HOC/UnauthenticatedRoutes';
import ChangePassword from './components/pages/ChangePassword';
import CompanyDetail from './components/pages/company/CompanyDetail';
import ErrorPage from './components/pages/ErrorPage';
import ForgotPassword from './components/pages/ForgotPassword';
import Home from './components/pages/Home';
import FirstProfile from './components/pages/IsiProfile/FirstProfile';
import SecondProfile from './components/pages/IsiProfile/SecondProfile';
import SixthProfile from './components/pages/IsiProfile/SixthProfile';
import ThirdProfile from './components/pages/IsiProfile/ThirdProfile';
import Login from './components/pages/login/Login';
import Bookmark from './components/pages/lowongan/bookmark/Bookmark';
import Lowongan from './components/pages/lowongan/Lowongan';
import LowonganDetail from './components/pages/lowongan/LowonganDetail/LowonganDetail';
import NotFound from './components/pages/NotFound';
import EditProfile from './components/pages/profile/EditProfile';
import StatusLamaran from './components/pages/profile/statusLamaran/StatusLamaran';
import Register from './components/pages/register/Register';
import SyaratKetentuan from './components/pages/syaratKetentuan/SyaratKetentuan';
import VerificationIndex from './components/pages/verification/VerificationIndex';

function App() {
	const router = createBrowserRouter([
		{
			errorElement: <ErrorPage />,
			children: [
				{
					path: '/',
					element: <Home />,
				},
				{
					path: '/syarat-ketentuan',
					element: <SyaratKetentuan />,
				},
				{
					path: '/lowongan',
					element: <Lowongan />,
				},
				{
					path: '/lowongan/:id',
					element: <LowonganDetail />,
				},
				{
					path: '/company/:id',
					element: <CompanyDetail />,
				},
				{
					element: <UnauthenticatedRoutes />,
					children: [
						{
							path: 'login',
							element: <Login />,
						},
						{
							path: 'register',
							element: <Register />,
						},
						{
						path: 'forgot-password',
							element: <ForgotPassword />,
						},
						{
							path: 'forgot-password/change',
							element: <ChangePassword />,
						},
						{
							path: 'verification',
							element: <VerificationIndex />,
						},
					],
				},
				{
					element: <AuthenticatedRoutes />,
					children: [
						{
							path: 'isi-profile',
							children: [
								{
									path: '1',
									element: <FirstProfile />,
								},
								{
									path: '2',
									element: <SecondProfile />,
								},
								{
									path: '3',
									element: <ThirdProfile />,
								},
								{
									path: '4',
									element: <SixthProfile />,
								},
							],
						},
						{
							path: 'profile',
							element: <EditProfile />,
						},
						{
							path: '/lowongan/status-lamaran',
							element: <StatusLamaran />,
						},
						{
							path: '/lowongan/bookmark',
							element: <Bookmark />,
						},
					],
				},
				{
					path: '*',
					element: <NotFound />,
				},
			],
		},
	]);
	return <RouterProvider router={router} />;
}

export default App;
