import { Select } from 'antd';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { setDone, setLoading } from '../../../store/loading';
import axiosConfig from '../../../utils/axiosConfig';
import { API_ENDPOINT } from '../../../utils/config';
import { Toast } from '../../reusable/Toast';
import Navbar from './_partials/Navbar';

const { Option } = Select;

function SixthProfile() {
	const [userSkills, setUserSkills] = useState([]);
	const [skills, setSkills] = useState([]);
	const [errorMessage, setErrorMessage] = useState({});
	const [loadingSkills, setLoadingSkills] = useState(false);
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const onSubmit = async () => {
		try {
			dispatch(setLoading());

			const response = await axiosConfig.post(`${API_ENDPOINT}/pencaker/user-skill`, { skills: userSkills });
			Toast.fire({
				icon: 'success',
				title: response.data.info,
				iconColor: '#17afe3',
			});
			setErrorMessage({});
			dispatch(setDone());
			navigate({ pathname: '/lowongan' });
		} catch (error) {
			console.log(error);
			Toast.fire({
				icon: 'error',
				iconColor: '#EA4735',
				title: error.response?.data.info ?? 'Terjadi Suatu Error',
			});
			dispatch(setDone());
			setErrorMessage(error.response?.data?.data?.errors);
		}
	};

	const fetchSkills = async () => {
		try {
			setLoadingSkills(true);
			const response = await axiosConfig.get(`${API_ENDPOINT}/pencaker/skills`);
			setSkills(response.data.data.skills);
		} catch (error) {
			console.log(error);
			Toast.fire({
				icon: 'error',
				iconColor: '#EA4735',
				title: error.response?.data.info ?? 'Terjadi Suatu Error',
			});
			setErrorMessage(error.response?.data?.data?.errors);
		} finally {
			setLoadingSkills(false);
		}
	};

	const handleChange = value => {
		setUserSkills(value);
	};

	const addSkill = name => {
		if (!userSkills.includes(name)) {
			setUserSkills([...userSkills, name]);
		}
	};

	useEffect(() => {
		fetchSkills();
	}, []);

	const tagRender = ({ label, onClose }) => {
		return <TagComponent label={label} onClose={onClose} />;
	};

	const TagComponent = ({ onClose, label }) => {
		return (
			<div className="flex items-center gap-2 py-2 lg:py-4 px-3 lg:px-5 bg-secondary bg-opacity-20 rounded-full my-3 mr-3">
				<label className="text-secondary font-bold text-xs lg:text-base">{label}</label>
				<div onClick={onClose} className="cursor-pointer">
					<img src="/images/close-circle.svg" className="w-4 lg:w-6" alt="" />
				</div>
			</div>
		);
	};

	return (
		<div
			className="antialiased bg-white text-gray-custom bg-no-repeat bg-contain bg-center"
			style={{ backgroundImage: "url('/images/bg-isi.png')" }}
		>
			<Navbar href={'/lowongan'} />
			<div className="flex items-center justify-center mb-16">
				<div className="w-full lg:w-2/5 marker:border-gray-custom rounded-lg box-border mt-16">
					{/* PROGRESS BAR */}
					<h1 className="text-sky-300 text-center mb-5 font-bold lg:font-normal text-xl">Step 4/4</h1>
					<div className="h-3 relative max-w-screen lg:max-w-2xl min-w-max rounded-full overflow-hidden">
						<div className="w-full h-full bg-sky-100 absolute"></div>
						<div className="h-full bg-sky-500 absolute" style={{ width: '100%' }}></div>
					</div>
					<div>
						<h1 className="text-gray-400 text-center mb-4 pt-5 text-lg">
							Beritahu apa yang kamu kuasai untuk menarik minat perusahaan terbaik.
						</h1>
						<div className="px-4 lg:px-5">
							{/* <!-- TEXTAREA --> */}
							<div className="flex justify-center">
								<div className="mb-3 mt-5 w-full">
									<Select
										mode="tags"
										style={{
											width: '100%',
										}}
										placeholder="Tags Mode"
										onChange={handleChange}
										value={userSkills}
										size="large"
										tagRender={tagRender}
										showArrow
									>
										{skills.map(skill => (
											<Option key={skill.id}>{skill.name}</Option>
										))}
									</Select>
								</div>
							</div>

							{/* <!-- PILIHAN SKILL --> */}
							<div className=" lg:px-5">
								<h1 className="text-gray-400 text-center mb-4 pt-5 text-lg">
									Atau kamu dapat menambahkan skill berikut:
								</h1>
								<div className="w-full flex lg:flex-wrap flex-col lg:flex-row gap-2 items-center justify-center">
									{/* <!-- ADOBE ILUSTRATOR --> */}
									<button
										onClick={() => addSkill('Adobe Ilustrator')}
										className="flex border-dashed border-2 border-primary mt-3 bg-sky-100 rounded-xl py-4 px-6"
									>
										<div className="flex items-center text-center justify-center">
											<img src="/images/step/rounded-plus.svg" alt="" className="w-7 mr-4" />
											<div>Adobe Ilustrator</div>
										</div>
									</button>
									{/* <!-- UI/UX Design --> */}
									<button
										onClick={() => addSkill('UI/UX Design')}
										className="flex border-dashed border-2 border-primary mt-3 bg-sky-100 rounded-xl py-4 px-6"
									>
										<div className="flex items-center text-center justify-center">
											<img src="/images/step/rounded-plus.svg" alt="" className="w-7 mr-4" />
											<div>UI/UX Design</div>
										</div>
									</button>
									{/* <!-- Design Thinking --> */}
									<button
										onClick={() => addSkill('Design Thinking')}
										className="flex border-dashed border-2 border-primary mt-3 bg-sky-100 rounded-xl py-4 px-6"
									>
										<div className="flex items-center text-center justify-center">
											<img src="/images/step/rounded-plus.svg" alt="" className="w-7 mr-4" />
											<div>Design Thinking</div>
										</div>
									</button>
									{/* <!-- Graphic Design --> */}
									<button
										onClick={() => addSkill('Graphic Design')}
										className="flex border-dashed border-2 border-primary mt-3 bg-sky-100 rounded-xl py-4 px-6"
									>
										<div className="flex items-center text-center justify-center">
											<img src="/images/step/rounded-plus.svg" alt="" className="w-7 mr-4" />
											<div>Graphic Design</div>
										</div>
									</button>
									{/* <!-- Adobe Photoshop --> */}
									<button
										onClick={() => addSkill('Adobe Photoshop')}
										className="flex border-dashed border-2 border-primary mt-3 bg-sky-100 rounded-xl py-4 px-6"
									>
										<div className="flex items-center text-center justify-center">
											<img src="/images/step/rounded-plus.svg" alt="" className="w-7 mr-4" />
											<div>Adobe Photoshop</div>
										</div>
									</button>
								</div>
							</div>

							{/*  TOMBOL DI BAWAH */}
							<div className="mt-24 lg:mt-12 w-full lg:flex justify-between items-center max-w-lg mx-auto">
								<Link to="/isi-profile/3" className="flex items-center gap-4">
									<img src="/images/arrow-orange.svg" className="h-4" alt="" />
									<div className="hidden lg:flex items-center gap-4 cursor-pointer">
										<p className="text-orange-400">Kembali</p>
									</div>
								</Link>
								<button
									onClick={onSubmit}
									className="bg-secondary px-6 py-4 my-3 hover:bg-opacity-90 text-white font-bold border border-secondary rounded-lg cursor-pointer w-full lg:w-auto"
								>
									Lanjutkan
								</button>
								<Link to="/lowongan" className="lg:hidden">
									<div className="rounded-xl border text-center justify-center items-center flex lg:hidden p-5 leading-none text-gray-400 border-gray-400 border-bold ">
										<p>Lewati langkah ini</p>
									</div>
								</Link>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default SixthProfile;
