import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import React from 'react';

const loadingIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

function SubmitButton({ dirty, isValid, isSubmitting, className, children, ...others }) {
	const isDisabled = () => {
		let dirtyCheck = dirty ?? true;
		let isValidCheck = isValid ?? true;
		let isSubmittingCheck = isSubmitting ? isSubmitting : false;
		return !(dirtyCheck && isValidCheck) || isSubmittingCheck;
	};
	return (
		<button
			type="submit"
			className={`w-full font-bold border text-white  rounded-xl text-center text-xl flex gap-4 justify-center  ${className} ${
				!isDisabled()
					? 'border-secondary bg-secondary text-white hover:bg-opacity-90 cursor-pointer'
					: 'border-gray-custom bg-gray-custom border-opacity-20 bg-opacity-20 cursor-not-allowed'
			}`}
			disabled={isDisabled()}
			{...others}
		>
			{children ?? 'Submit'}
			{isSubmitting && <Spin indicator={loadingIcon} />}
		</button>
	);
}

export default SubmitButton;
